import { Button, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { FormTemplateStatus, Row, SitelineText } from 'siteline-common-web'
import { TemplateInfoIcon } from '../../common/components/TemplateInfoIcon'
import { VariantAutocomplete } from '../../common/components/VariantAutocomplete'
import {
  FormTemplateType,
  useTemplateVariantForAutocompleteLazyQuery,
} from '../../common/graphql/apollo-operations'
import { FormTemplateStatusChip } from '../FormTemplateStatusChip'

const StyledLienWaiverTemplateSetTable = styled(Table)(() => ({
  tableLayout: 'fixed',
  '& .link': {
    width: 100,
  },
}))

export type TemplateVariantWithId = {
  id: string
  template: {
    id: string
    type: FormTemplateType
    status: FormTemplateStatus
    skippedValidation: boolean
    createdAt: string
  }
}

interface LienWaiverTemplateSetTableProps {
  isEditing: boolean
  conditionalFinalVariant: TemplateVariantWithId | null
  onUpdateConditionalFinal: (variant: TemplateVariantWithId | null) => void
  conditionalProgressVariant: TemplateVariantWithId | null
  onUpdateConditionalProgress: (variant: TemplateVariantWithId | null) => void
  unconditionalFinalVariant: TemplateVariantWithId | null
  onUpdateUnconditionalFinal: (variant: TemplateVariantWithId | null) => void
  unconditionalProgressVariant: TemplateVariantWithId | null
  onUpdateUnconditionalProgress: (variant: TemplateVariantWithId | null) => void
}

export function LienWaiverTemplateSetTable({
  isEditing,
  conditionalFinalVariant,
  onUpdateConditionalFinal,
  conditionalProgressVariant,
  onUpdateConditionalProgress,
  unconditionalFinalVariant,
  onUpdateUnconditionalFinal,
  unconditionalProgressVariant,
  onUpdateUnconditionalProgress,
}: LienWaiverTemplateSetTableProps) {
  const [getVariant] = useTemplateVariantForAutocompleteLazyQuery()

  const rows = useMemo(
    () => [
      {
        name: 'Conditional Final',
        key: 'conditionalFinalVariant',
        variant: conditionalFinalVariant,
        setVariant: onUpdateConditionalFinal,
      },
      {
        name: 'Conditional Progress',
        key: 'conditionalProgressVariant',
        variant: conditionalProgressVariant,
        setVariant: onUpdateConditionalProgress,
      },
      {
        name: 'Unconditional Final',
        key: 'unconditionalFinalVariant',
        variant: unconditionalFinalVariant,
        setVariant: onUpdateUnconditionalFinal,
      },
      {
        name: 'Unconditional Progress',
        key: 'unconditionalProgressVariant',
        variant: unconditionalProgressVariant,
        setVariant: onUpdateUnconditionalProgress,
      },
    ],
    [
      conditionalFinalVariant,
      conditionalProgressVariant,
      unconditionalFinalVariant,
      unconditionalProgressVariant,
      onUpdateConditionalFinal,
      onUpdateConditionalProgress,
      onUpdateUnconditionalFinal,
      onUpdateUnconditionalProgress,
    ]
  )

  const getRowStatus = useCallback((variant: TemplateVariantWithId | null) => {
    const type = variant?.template.type ?? FormTemplateType.LIEN_WAIVER
    return {
      status: variant?.template.status ?? null,
      skippedValidation: variant?.template.skippedValidation ?? false,
      isWrongType: type !== FormTemplateType.LIEN_WAIVER,
      template: variant?.template ?? null,
    }
  }, [])

  const handleViewVariant = useCallback(
    async (variantId: string) => {
      const data = await getVariant({ variables: { id: variantId } })
      const templateId = data.data?.formTemplateVariant.template.id
      if (templateId) {
        window.open(`/templates/${templateId}`, '_blank')
      }
    },
    [getVariant]
  )

  return (
    <StyledLienWaiverTemplateSetTable>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Template</TableCell>
          <TableCell>Template Status</TableCell>
          <TableCell className="link">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ name, key, variant, setVariant }) => {
          const { status, skippedValidation, isWrongType, template } = getRowStatus(variant)
          return (
            <TableRow key={key}>
              <TableCell>{name}</TableCell>
              <TableCell>
                <VariantAutocomplete
                  disabled={!isEditing}
                  templateType={FormTemplateType.LIEN_WAIVER}
                  variant={variant?.id ?? null}
                  onVariantChange={setVariant}
                />
              </TableCell>
              <TableCell>
                <Row>
                  {status && (
                    <FormTemplateStatusChip status={status} skippedValidation={skippedValidation} />
                  )}
                  {isWrongType && (
                    <SitelineText variant="body2" color="red70">
                      Wrong template type?
                    </SitelineText>
                  )}
                  {template && <TemplateInfoIcon template={template} sx={{ marginLeft: 1 }} />}
                </Row>
              </TableCell>
              <TableCell>
                {variant && (
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleViewVariant(variant.id)
                    }}
                  >
                    View
                  </Button>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </StyledLienWaiverTemplateSetTable>
  )
}
