import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import { Alert, Button, Container, Grid2, InputAdornment, TextField, Theme } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Column, makeStylesFast, SitelineText, Spacer } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import { useGetCompanyForDetailsQuery } from '../../common/graphql/apollo-operations'
import { CompanyFormSets } from './CompanyFormSets'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
}))

type CompanyFormSetsParams = {
  companyId?: string
}

export default function CompanyFormSetsPage() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { companyId } = useParams() as CompanyFormSetsParams

  const [searchQuery, setSearchQuery] = useState<string>('')

  if (!companyId) {
    throw new Error('No company ID provided')
  }

  const { data, error, loading } = useGetCompanyForDetailsQuery({
    variables: { companyId },
  })

  const canSearch = useMemo(() => {
    if (!data?.company) {
      return false
    }
    return (
      [
        ...data.company.payAppFormTemplateSets,
        ...data.company.primaryLienWaiverTemplateSets,
        ...data.company.vendorLienWaiverTemplateSets,
      ].length > 0
    )
  }, [data?.company])

  const handleBackToCompany = useCallback(
    () => navigate(`/companies/${companyId}`),
    [navigate, companyId]
  )

  const tabName = data?.company.name ?? 'Form sets'
  const backButtonText = data?.company.name ?? 'Back to company'

  return (
    <Page className={classes.root} title={tabName}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Button onClick={handleBackToCompany} startIcon={<ArrowBackIcon />}>
            {backButtonText}
          </Button>
          <Grid2 size={{ xs: 12 }}>
            <Column gap={16}>
              <SitelineText variant="h4">Form sets</SitelineText>
              {canSearch && (
                <TextField
                  value={searchQuery}
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                  style={{ width: '50%', backgroundColor: 'white' }}
                  size="small"
                  variant="outlined"
                  placeholder="Search by form set name, GC, or state"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            </Column>
          </Grid2>
          <Spacer minHeight={16} />
          <Grid2 size={{ xs: 12 }}>
            {data?.company && <CompanyFormSets company={data.company} searchQuery={searchQuery} />}
          </Grid2>
        </Grid2>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
