import { gql } from '@apollo/client'
import { Alert, Container, Theme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import {
  FormTemplateForDetailsQuery,
  useFormTemplateForDetailsQuery,
  useUpdatedFormTemplateSubscription,
} from '../../common/graphql/apollo-operations'
import FormTemplateDetailsContent from './FormTemplateDetailsContent'

gql`
  query getFormTemplate($id: ID!) {
    formTemplate(id: $id) {
      ...FormTemplateProperties
      requestingCompany {
        id
        name
        picture {
          id
          url
        }
        notaryStamp {
          id
          url
        }
        notarySignature {
          id
          url
        }
        secondarySignature {
          id
          url
        }
      }
    }
  }
  ${fragments.formTemplate}
`

gql`
  query formTemplateForDetails($id: ID!) {
    formTemplate(id: $id) {
      id
      createdAt
      type
      status
      statusLogs {
        ...FormTemplateStatusLogProperties
      }
      skippedValidation
      userVisibleName
      isCustomerReady
      tags
      dueDate
      associatedCompany {
        id
      }
      requestingCompany {
        id
      }
      associatedContracts {
        id
        providedAsFormType
        contract {
          id
          daysBeforePayAppDue
          project {
            id
            name
            timeZone
            metadata {
              payAppDueOnDayOfMonth
            }
          }
          company {
            id
            name
          }
        }
      }
      owner {
        id
        email
        firstName
        lastName
      }
      builder {
        id
        email
        firstName
        lastName
      }
      validator {
        id
        email
        firstName
        lastName
      }
      versions {
        ...FormTemplateVersionProperties
      }
      variants {
        id
        internalName
        isDefaultVariant
      }
      comments {
        ...FormTemplateCommentProperties
      }
      originalFile {
        ...StoredFileProperties
      }
      duplicateTemplate {
        id
        userVisibleName
      }
    }
  }
  ${fragments.formTemplate}
  ${fragments.formTemplateVersion}
  ${fragments.formTemplateStatusLog}
  ${fragments.formTemplateComment}
  ${fragments.storedFile}
`

gql`
  subscription updatedFormTemplate($id: ID!) {
    updatedFormTemplate(id: $id) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  formTemplate: {
    width: '100%',
  },
}))

type FormTemplateDetailParams = {
  templateId: string
}

export type FormTemplateForDetails = FormTemplateForDetailsQuery['formTemplate']

export default function FormTemplateDetails() {
  const classes = useStyles()
  const { templateId } = useParams() as FormTemplateDetailParams

  const { data, error, loading } = useFormTemplateForDetailsQuery({
    variables: { id: templateId },
  })

  // Add subscription to read when form template updates occur
  useUpdatedFormTemplateSubscription({ variables: { id: templateId } })

  const title = data?.formTemplate.userVisibleName ?? 'Form Template'

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        {data?.formTemplate && <FormTemplateDetailsContent formTemplate={data.formTemplate} />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
