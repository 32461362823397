import { gql } from '@apollo/client'
import { Autocomplete, Button, styled, TextField } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ButtonLabelSpinner,
  Column,
  fuseSearch,
  Row,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { StateDropdown } from '../../common/components/StateDropdown'
import {
  CompanyLienWaiverTemplateSetProperties,
  useCreateLienWaiverFormTemplateSetMutation,
  useDeleteLienWaiverFormTemplateSetMutation,
  useUpdateLienWaiverFormTemplateSetMutation,
} from '../../common/graphql/apollo-operations'
import * as fragments from '../../common/graphql/Fragments'
import {
  LienWaiverTemplateSetTable,
  TemplateVariantWithId,
} from '../lien-waiver-template-set/LienWaiverTemplateSetTable'
import { FormSetsCard } from './FormSetCard'

const StyledLienWaiverFormSet = styled(Column)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: 0,
  '& .editingTopLevel': {
    paddingTop: theme.spacing(2),
  },
  '& .deleteButton': {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(2),
  },
}))

gql`
  mutation createLienWaiverFormTemplateSet($input: CreateLienWaiverFormTemplateSetInput!) {
    createLienWaiverFormTemplateSet(input: $input) {
      id
      primaryLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
      vendorLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
    }
  }
  ${fragments.companyLienWaiverTemplateSet}
`

gql`
  mutation updateLienWaiverFormTemplateSet($input: UpdateLienWaiverFormTemplateSetInput!) {
    updateLienWaiverFormTemplateSet(input: $input) {
      id
      primaryLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
      vendorLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
    }
  }
  ${fragments.companyLienWaiverTemplateSet}
`

gql`
  mutation deleteLienWaiverFormTemplateSet($input: DeleteLienWaiverFormTemplateSetInput!) {
    deleteLienWaiverFormTemplateSet(input: $input) {
      id
      primaryLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
      vendorLienWaiverTemplateSets {
        ...CompanyLienWaiverTemplateSetProperties
      }
    }
  }
  ${fragments.companyLienWaiverTemplateSet}
`

type GeneralContractorOption = {
  id: string
  name: string
}

const EMPTY_GC_OPTION = {
  id: '',
  name: '',
}

interface LienWaiverFormSetProps {
  lienWaiverFormSet: CompanyLienWaiverTemplateSetProperties | null
  onCancelCreate: () => void
  generalContractors: GeneralContractorOption[]
  scrollToId: string | null
  isVendorSet: boolean
  companyId: string
}

export function LienWaiverFormSet({
  lienWaiverFormSet,
  onCancelCreate,
  generalContractors,
  scrollToId,
  isVendorSet,
  companyId,
}: LienWaiverFormSetProps) {
  const snackbar = useSitelineSnackbar()

  const isNewFormSet = lienWaiverFormSet === null
  const initialFormName = lienWaiverFormSet?.name ?? ''
  const initialGc = lienWaiverFormSet?.generalContractor ?? EMPTY_GC_OPTION
  const initialState = lienWaiverFormSet?.state ?? null
  const initialIsOpen = !!scrollToId && scrollToId === lienWaiverFormSet?.id

  const {
    conditionalFinalVariant: initialConditionalFinal,
    conditionalProgressVariant: initialConditionalProgress,
    unconditionalFinalVariant: initialUnconditionalFinal,
    unconditionalProgressVariant: initialUnconditionalProgress,
  } = useMemo(() => {
    if (lienWaiverFormSet?.lienWaivers) {
      return lienWaiverFormSet.lienWaivers
    }
    return {
      conditionalFinalVariant: null,
      conditionalProgressVariant: null,
      unconditionalFinalVariant: null,
      unconditionalProgressVariant: null,
    }
  }, [lienWaiverFormSet?.lienWaivers])

  const [isEditing, handleEdit, handleCancelEdit] = useToggle()
  const [formName, setFormName] = useState<string>(initialFormName)
  const [gc, setGc] = useState<GeneralContractorOption>(initialGc)
  const [state, setState] = useState<string | null>(initialState)
  const [gcSearchQuery, setGcSearchQuery] = useState<string>('')
  const [isGcAutocompleteOpen, handleOpenGcAutocomplete, handleCloseGcAutocomplete] = useToggle()

  const [conditionalFinal, setConditionalFinal] = useState<TemplateVariantWithId | null>(
    initialConditionalFinal
  )
  const [conditionalProgress, setConditionalProgress] = useState<TemplateVariantWithId | null>(
    initialConditionalProgress
  )
  const [unconditionalFinal, setUnconditionalFinal] = useState<TemplateVariantWithId | null>(
    initialUnconditionalFinal
  )
  const [unconditionalProgress, setUnconditionalProgress] = useState<TemplateVariantWithId | null>(
    initialUnconditionalProgress
  )

  const [createTemplateSet, { loading: creating }] = useCreateLienWaiverFormTemplateSetMutation()
  const [updateTemplateSet, { loading: updating }] = useUpdateLienWaiverFormTemplateSetMutation()
  const [deleteTemplateSet, { loading: deleting }] = useDeleteLienWaiverFormTemplateSetMutation()

  const filteredGeneralContractors = useMemo(() => {
    return fuseSearch(generalContractors, gcSearchQuery, ['name'], { ignoreLocation: true })
  }, [generalContractors, gcSearchQuery])

  const handleReset = useCallback(() => {
    setFormName(initialFormName)
    setGc(initialGc)
    setState(initialState)
    setGcSearchQuery('')
    handleCloseGcAutocomplete()
    setConditionalFinal(initialConditionalFinal)
    setConditionalProgress(initialConditionalProgress)
    setUnconditionalFinal(initialUnconditionalFinal)
    setUnconditionalProgress(initialUnconditionalProgress)
  }, [
    handleCloseGcAutocomplete,
    initialConditionalFinal,
    initialConditionalProgress,
    initialFormName,
    initialGc,
    initialState,
    initialUnconditionalFinal,
    initialUnconditionalProgress,
  ])

  const handleCancel = useCallback(() => {
    handleReset()
    handleCancelEdit()
    onCancelCreate()
  }, [handleCancelEdit, handleReset, onCancelCreate])

  const handleDelete = useCallback(async () => {
    if (lienWaiverFormSet === null) {
      handleCancel()
      return
    }
    const confirmed = window.confirm(`Are you sure you want to delete ${lienWaiverFormSet.name}`)
    if (!confirmed) {
      return
    }
    try {
      await deleteTemplateSet({
        variables: {
          input: {
            templateSetId: lienWaiverFormSet.id,
          },
        },
      })
    } catch (error) {
      snackbar.showError(error.message)
      return
    }
  }, [deleteTemplateSet, handleCancel, lienWaiverFormSet, snackbar])

  const handleSave = useCallback(async () => {
    try {
      const templateSetInput = {
        conditionalFinalVariantId: conditionalFinal?.id ?? null,
        conditionalProgressVariantId: conditionalProgress?.id ?? null,
        unconditionalFinalVariantId: unconditionalFinal?.id ?? null,
        unconditionalProgressVariantId: unconditionalProgress?.id ?? null,
      }
      const input = {
        name: formName,
        generalContractorCompanyId: gc.id || null,
        state,
        lienWaivers: templateSetInput,
      }

      if (isNewFormSet) {
        await createTemplateSet({
          variables: {
            input: {
              ...input,
              companyId,
              isVendorSet,
            },
          },
        })
      } else {
        await updateTemplateSet({
          variables: {
            input: {
              ...input,
              templateSetId: lienWaiverFormSet.id,
            },
          },
        })
      }
    } catch (error) {
      snackbar.showError(error.message)
      return
    }
    handleCancelEdit()
    onCancelCreate()
  }, [
    companyId,
    conditionalFinal?.id,
    conditionalProgress?.id,
    createTemplateSet,
    formName,
    gc.id,
    handleCancelEdit,
    isNewFormSet,
    isVendorSet,
    lienWaiverFormSet?.id,
    snackbar,
    state,
    unconditionalFinal?.id,
    unconditionalProgress?.id,
    updateTemplateSet,
    onCancelCreate,
  ])

  useEffect(() => {
    handleReset()
  }, [handleReset])

  const isEditingOrCreating = isEditing || isNewFormSet
  const isLoading = creating || updating || deleting

  return (
    <FormSetsCard
      name={initialFormName}
      isNewFormSet={isNewFormSet}
      isEditing={isEditing}
      onEdit={handleEdit}
      onCancelEdit={handleCancel}
      onSave={handleSave}
      initialIsOpen={initialIsOpen}
      disableCancel={isLoading}
      disableSave={!formName || isLoading}
      saving={creating || updating}
      gc={gc.name}
      state={state ?? ''}
    >
      <StyledLienWaiverFormSet id={lienWaiverFormSet?.id}>
        {isEditingOrCreating && (
          <Row gap={8}>
            <TextField
              label="Form set name*"
              type="text"
              size="small"
              value={formName}
              onChange={(ev) => setFormName(ev.target.value)}
              style={{ width: 300 }}
            />
            <Autocomplete
              options={filteredGeneralContractors}
              open={isGcAutocompleteOpen}
              onOpen={handleOpenGcAutocomplete}
              onClose={handleCloseGcAutocomplete}
              inputValue={gcSearchQuery}
              onInputChange={(_, value) => setGcSearchQuery(value)}
              getOptionLabel={(option) => option.name}
              value={gc}
              size="small"
              style={{ width: 300 }}
              onChange={(_, value) => setGc(value ?? EMPTY_GC_OPTION)}
              renderInput={(params) => <TextField {...params} label="General contractor" />}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
            />
            <StateDropdown state={state} onStateChange={setState} />
          </Row>
        )}
        <LienWaiverTemplateSetTable
          isEditing={isEditingOrCreating}
          conditionalFinalVariant={conditionalFinal}
          onUpdateConditionalFinal={setConditionalFinal}
          conditionalProgressVariant={conditionalProgress}
          onUpdateConditionalProgress={setConditionalProgress}
          unconditionalFinalVariant={unconditionalFinal}
          onUpdateUnconditionalFinal={setUnconditionalFinal}
          unconditionalProgressVariant={unconditionalProgress}
          onUpdateUnconditionalProgress={setUnconditionalProgress}
        />
        {isEditing && (
          <Button
            variant="text"
            color="error"
            disabled={isLoading}
            startIcon={deleting ? <ButtonLabelSpinner /> : undefined}
            onClick={handleDelete}
            className="deleteButton"
          >
            Delete
          </Button>
        )}
      </StyledLienWaiverFormSet>
    </FormSetsCard>
  )
}
