import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { useCloudLoggingLogsQuery } from '../../common/graphql/apollo-operations'
import { Job, LogEntry, openLogs, Task, timestampToMoment } from './JobsPage'
import { TaskRow } from './TaskRow'

const useStyles = makeStylesFast(() => ({
  root: {
    height: 500,
    overflow: 'auto',
    '& .MuiTableFooter-root': {
      position: 'sticky',
      insetBlockEnd: 0,
      background: 'white',
    },
  },
}))

type TaskListProps = {
  job: Job | null
  task: Task | null
  onTaskSelected: (task: Task) => void
  search: string
}

/**
 * List of tasks for a given job.
 */
export default function TaskList({ job, task, search, onTaskSelected }: TaskListProps) {
  const classes = useStyles()

  // Gathers recent Cloud Tasks activity logs that have a response, which means the task was
  // properly dispatched and the cron service responded.
  const filter = useMemo(() => {
    if (search.length > 0) {
      const oneDayAgo = moment.utc().subtract(1, 'week')
      return `
        resource.type="cloud_tasks_queue"
        severity>=DEFAULT
        jsonPayload.@type="type.googleapis.com/google.cloud.tasks.logging.v1.TaskActivityLog"
        jsonPayload.attemptResponseLog:*
        jsonPayload.task:"${search}"
        timestamp>="${oneDayAgo.toISOString()}"
      `
    }
    if (!job) {
      return null
    }
    const queue = _.kebabCase(job.name)
    const jobStart = timestampToMoment(job.startLog.timestamp)
    const jobEnd = jobStart.clone().add(3, 'hours')
    return `
      resource.type="cloud_tasks_queue"
      resource.labels.queue_id="${queue}"
      severity>=DEFAULT
      jsonPayload.@type="type.googleapis.com/google.cloud.tasks.logging.v1.TaskActivityLog"
      jsonPayload.attemptResponseLog:*
      timestamp>="${jobStart.toISOString()}"
      timestamp<="${jobEnd.toISOString()}"
    `
  }, [job, search])

  const { data, loading } = useCloudLoggingLogsQuery({
    variables: {
      input: { filter: filter ?? '', pageSize: 10_000 },
    },
    skip: !filter,
  })

  const tasks = useMemo(() => {
    const entries = (data?.cloudLoggingLogs.entries ?? []) as LogEntry[]
    const mapped = entries.map((entry): Task => {
      // Eg: projects/siteline-prod/locations/us-central1/queues/textura-messages/tasks/a06f44033a_integrationId-cd950658-5746-471c-868a-e4a029c098aa
      const name = entry.message.task as string

      // Eg: a06f44033a_integrationId-cd950658-5746-471c-868a-e4a029c098aa
      const id = name.split('/tasks/')[1]

      const separated = id.split('_')

      // Eg: a06f44033a
      const prefix = separated[0]

      // Eg: integrationId-cd950658-5746-471c-868a-e4a029c098aa
      const rest = _.tail(separated)

      // Eg: { integrationId: 'cd950658-5746-471c-868a-e4a029c098aa' }
      const metadata: { [key: string]: string } = {}
      for (const metadataString of rest) {
        const split = metadataString.split('-')
        const key = split[0]
        const value = _.tail(split).join('-')
        metadata[key] = value
      }

      // Job name, either from the selected entrypoint job, or from the task log
      let jobName: string
      if (job) {
        jobName = job.name
      } else {
        jobName = _.camelCase(entry.resource.labels.queue_id)
      }

      return {
        id,
        prefix,
        metadata,
        startLog: entry,
        endLog: null,
        jobName,
      }
    })

    // Only keep the most recent attempt of each task, because it's most likely to be successful.
    // Task logs will contain all attempts.
    const sorted = _.chain(mapped)
      .orderBy((task) => timestampToMoment(task.startLog.timestamp).valueOf(), 'desc')
      .uniqBy((task) => task.id)
      .value()

    return sorted
  }, [data?.cloudLoggingLogs.entries, job])

  const [successfulTasks, failedTasks] = useMemo(() => {
    return _.partition(tasks, (task) => task.startLog.severity === 'INFO')
  }, [tasks])

  const handleOpenLogs = useCallback(() => {
    if (!filter) {
      return
    }
    openLogs({ filter })
  }, [filter])

  useEffect(() => {
    if (tasks.length === 1) {
      onTaskSelected(tasks[0])
    }
  }, [tasks, onTaskSelected])

  const includeJobName = search.length > 0
  const fullWidthColSpan = includeJobName ? 4 : 3

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 30 }}></TableCell>
            {includeJobName && <TableCell>Job</TableCell>}
            <TableCell sx={{ width: 120 }}>Task</TableCell>
            <TableCell>Metadata</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              {includeJobName && (
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
              )}
              <TableCell>
                <Skeleton variant="text" width={30} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={200} />
              </TableCell>
            </TableRow>
          )}
          {failedTasks.map((taskInList) => (
            <TaskRow
              key={taskInList.startLog['logging.googleapis.com/insertId']}
              task={taskInList}
              selected={taskInList.id === task?.id}
              onClick={() => onTaskSelected(taskInList)}
              includeJobName={includeJobName}
            />
          ))}
          {successfulTasks.map((taskInList) => (
            <TaskRow
              key={taskInList.startLog['logging.googleapis.com/insertId']}
              task={taskInList}
              selected={taskInList.id === task?.id}
              onClick={() => onTaskSelected(taskInList)}
              includeJobName={includeJobName}
            />
          ))}
          {(search.length > 0 || job !== null) && tasks.length === 0 && !loading && (
            <TableRow>
              <TableCell colSpan={fullWidthColSpan}>No tasks found</TableCell>
            </TableRow>
          )}
          {search.length == 0 && job === null && tasks.length === 0 && !loading && (
            <TableRow>
              <TableCell colSpan={fullWidthColSpan}>No job/task selected</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={fullWidthColSpan}>
              <Button
                variant="text"
                color="primary"
                size="small"
                startIcon={<FormatAlignLeftIcon />}
                onClick={handleOpenLogs}
              >
                Open logs
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
