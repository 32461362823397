import { gql } from '@apollo/client'
import { Autocomplete, AutocompleteProps, Chip, TextField } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import {
  FormTemplateStatus,
  FormTemplateType,
  PaginatedListSortOrder,
  TemplateListSortCriteria,
  useDebouncedSearch,
  useSitelineSnackbar,
} from 'siteline-common-web'
import {
  useTemplateVariantForAutocompleteQuery,
  useTemplatesForAutocompleteQuery,
} from '../graphql/apollo-operations'
import { AutocompletedTemplate } from './TemplateAutocomplete'

gql`
  query templateVariantForAutocomplete($id: ID!) {
    formTemplateVariant(id: $id) {
      id
      userVisibleName
      internalName
      isDefaultVariant
      hidesZeroDollarAmounts
      roundPercentages
      useCompanyNotarySignatureIfAvailable
      template {
        id
        createdAt
        type
        userVisibleName
        status
        skippedValidation
        versions {
          id
          versionNumber
          isReadyForManualStoredMaterials
        }
      }
    }
  }
`

export type AutocompletedVariant = {
  id: string
  internalName: string
  isDefaultVariant: boolean
  template: AutocompletedTemplate
}

const collator = new Intl.Collator()

type VariantAutocompleteProps = Omit<
  AutocompleteProps<AutocompletedVariant, false, false, false>,
  'options' | 'renderInput'
> & {
  variant: string | AutocompletedVariant | null
  onVariantChange: (id: AutocompletedVariant | null) => void
  templateType: FormTemplateType | null
  label?: string
}

/**
 * Searches for variants
 */
export function VariantAutocomplete({
  variant,
  onVariantChange,
  templateType,
  label,
  ...rest
}: VariantAutocompleteProps) {
  const { search, debouncedSearch, onSearch } = useDebouncedSearch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const snackbar = useSitelineSnackbar()

  const { data: templatesData, loading } = useTemplatesForAutocompleteQuery({
    variables: {
      input: {
        search: debouncedSearch,
        type: templateType,
        sortCriteria: TemplateListSortCriteria.SEARCH_RELEVANCE,
        sortOrder: PaginatedListSortOrder.DESC,
      },
    },
    skip: debouncedSearch.length < 3 || !isOpen,
  })

  const variantId = useMemo(() => {
    if (!variant) {
      return null
    }
    if (_.isString(variant)) {
      return variant
    }
    return variant.id
  }, [variant])

  const { data: variantData } = useTemplateVariantForAutocompleteQuery({
    variables: {
      id: variantId ?? '',
    },
    skip: !variantId,
  })

  const options = useMemo((): AutocompletedVariant[] => {
    const autocompleted = (templatesData?.paginatedTemplates.templates ?? []).flatMap(
      (template): AutocompletedVariant[] => {
        const sortedVariants = [...template.variants].sort((a, b) => {
          if (a.isDefaultVariant) {
            return -1
          }
          if (b.isDefaultVariant) {
            return -1
          }
          return collator.compare(a.internalName.trim(), b.internalName.trim())
        })
        return sortedVariants.map((variant) => ({
          id: variant.id,
          internalName: variant.internalName,
          isDefaultVariant: variant.isDefaultVariant,
          template: {
            id: template.id,
            userVisibleName: template.userVisibleName,
            type: template.type,
            status: template.status,
            skippedValidation: template.skippedValidation,
            createdAt: template.createdAt,
          },
        }))
      }
    )

    const options = variantData
      ? [variantData.formTemplateVariant, ...autocompleted]
      : autocompleted
    return _.uniqBy(options, (variant) => variant.id)
  }, [variantData, templatesData?.paginatedTemplates.templates])

  const selectedOption = useMemo(() => {
    const found = options.find((option) => option.id === variantId)
    return found ?? null
  }, [options, variantId])

  const onOptionSelected = useCallback(
    (option: AutocompletedVariant | null) => {
      if (option && option.template.type !== templateType) {
        // It should only be possible to hit this case if the user searched by ID and the matching
        // form template is the incorrect type
        snackbar.showError('Incorrect form template type')
        onSearch('')
        onVariantChange(null)
        return
      }
      onVariantChange(option)
    },
    [onSearch, onVariantChange, snackbar, templateType]
  )

  const renderOption = useCallback((option: AutocompletedVariant) => {
    const variantLabel = option.isDefaultVariant ? 'Default' : option.internalName
    const variantColor = option.isDefaultVariant ? 'default' : 'secondary'
    return (
      <>
        <span>{option.template.userVisibleName}</span>
        <Chip size="small" label={variantLabel} color={variantColor} sx={{ marginLeft: 1 }} />
        {option.template.status !== FormTemplateStatus.COMPLETE && (
          <Chip size="small" label="Incomplete" color="warning" sx={{ marginLeft: 1 }} />
        )}
      </>
    )
  }, [])

  const getOptionLabel = useCallback((option: AutocompletedVariant) => {
    const variantLabel = option.isDefaultVariant ? 'Default' : option.internalName
    return `${option.template.userVisibleName} (${variantLabel})`
  }, [])

  return (
    <Autocomplete
      {...rest}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      size="small"
      loading={loading}
      loadingText="Loading variants..."
      inputValue={search}
      onInputChange={(ev, value) => onSearch(value)}
      getOptionLabel={getOptionLabel}
      filterOptions={(option) => option}
      options={options}
      includeInputInList
      onChange={(ev, value) => onOptionSelected(value)}
      value={selectedOption}
      getOptionKey={(option) => option.id}
      multiple={false}
      renderInput={(params) => <TextField label={label} {...params} />}
      // Required to provide an explicit child key
      // See https://stackoverflow.com/a/69396153
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {renderOption(option)}
        </li>
      )}
    />
  )
}
