import type { EmptyObject } from 'type-fest'
import { ImportIntegrationProjectNameSource } from '../enums.js'

export type CompanyIntegrationMetadataSpectrum = {
  // Map from user ID to batch code
  batches?: { [userId: string]: string }
}

// This type allows a user to set a company wide default GL account
// or retention GL account for a Foundation integration
export type CompanyIntegrationMetadataFoundation = {
  retentionLedgerAccount?: {
    agaveSourceId: string
  }
  ledgerAccount?: {
    agaveAccountId: string
  }
}

export type CompanyIntegrationMetadataVista = {
  // If the customer prefers entering invoice codes manually, default to manual entry when syncing
  useManualInvoiceCode?: boolean
  // If the customer prefers entering batch numbers manually, default to manual entry when syncing
  useManualBatchNumber?: boolean
  // If the contract line items have valid (contiguous) billing groups, we will import them as headings in the SOV
  groupLineItemsByBillGroup?: boolean
}

export const SAGE_INTACCT_INVOICE_TYPE_REGULAR = 'Regular'
export const SAGE_INTACCT_INVOICE_TYPE_PROJECT_CONTRACT = 'Project Contract'

// This type allows an admin to set the billing type that we will write invoices
// to for a given Sage Intacct instance. So far, we've seen AIA Billing and
// Project Contract billing types
export type CompanyIntegrationMetadataSageIntacct = {
  invoiceType: string
  retentionInvoiceType: string
  hasAutoNumberingEnabled: boolean
}

export type CompanyIntegrationMetadataCmic = {
  companyCode: string
}

/**
 * Exporting invoices for Quickbooks requires referencing a Quickbooks "Item" for every line on the
 * invoice, where each Item has an associate income account (i.e. Account entry with type INC).
 * Based on conversations with customers, it seems that customers most commonly use a single item
 * type for all progress items on their invoices, and another item type for all retention items.
 * Rather than have to set the item and account each time an invoice is exported on a new project,
 * customers will set these mappings once in their company settings, and they will be used on all
 * invoices exported for that company. These settings can also be override on a per-project basis,
 * by defining the corresponding value in the `Integration.metadata`.
 */
export type CompanyIntegrationMetadataQuickbooks = {
  /**
   * This is the AR account used for all progress pay apps exported to QuickBooks
   */
  accountsReceivableAccount: string
  /**
   * This is the AR account used for all retention pay apps exported to QuickBooks
   */
  retentionAccountsReceivableAccount?: string
  /**
   * This is the invoice item that will be used for all progress line items on an invoice export
   * IIF. It corresponds to the name of an !INVITEM entry in each invoice IIF file, which will
   * initially create and then reference an Item in Quickbooks.
   */
  progressItemName: string
  /**
   * This is the invoice account corresponding to the `progressItemName` above. It will be used on
   * the same !INVITEM entry in each invoice IIF file.
   */
  progressItemIncomeAccount: string
  /**
   * This is the invoice item that will be used for all retention line items on an invoice export
   * IIF. It corresponds to the name of an !INVITEM entry in each invoice IIF file, which will
   * initially create and then reference an Item in Quickbooks.
   */
  retentionItemName: string
  /**
   * This is the invoice account corresponding to the `retentionItemName` above. It will be used on
   * the same !INVITEM entry in each invoice IIF file.
   */
  retentionItemIncomeAccount: string
}

type CompanyIntegrationMetadataDefault = EmptyObject

export type CompanyIntegrationMetadata =
  | CompanyIntegrationMetadataSpectrum
  | CompanyIntegrationMetadataQuickbooks
  | CompanyIntegrationMetadataDefault
  | CompanyIntegrationMetadataSageIntacct
  | CompanyIntegrationMetadataFoundation
  | CompanyIntegrationMetadataVista
  | CompanyIntegrationMetadataCmic

// Interface used for storing past invoice codes for an hh2 integration, along with the latest hh2
// version number found in the last invoice response
export type Hh2CachedInvoiceCodes = {
  invoiceCodes: string[]
  version: number
}

// Interface used for storing past invoice codes for an Agave integration, along with the latest
// date found in the last invoice response
export type AgaveCachedInvoiceCodes = {
  invoiceCodes: string[]
  lastDate: string
}

export const DEFAULT_IMPORT_INTEGRATION_PROJECT_NAME_SOURCE =
  ImportIntegrationProjectNameSource.JOB_NAME

export function getSitelineProjectNameFromIntegrationProject({
  integrationProjectName,
  integrationContractName,
  importProjectNameSource,
}: {
  integrationProjectName: string
  integrationContractName: string | null | undefined
  importProjectNameSource: ImportIntegrationProjectNameSource
}): string {
  switch (importProjectNameSource) {
    case ImportIntegrationProjectNameSource.JOB_NAME:
      return integrationProjectName
    case ImportIntegrationProjectNameSource.CONTRACT_NAME:
      // We still fall back to the job name if there's no contract name, e.g. if the integration
      // doesn't always have a contract per job or the contract name isn't filled because it's the
      // same as the job name
      return integrationContractName || integrationProjectName
  }
}
