import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import {
  Box,
  Button,
  Grid2,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import { useCloudLoggingLogsQuery } from '../../common/graphql/apollo-operations'
import {
  getMessageFromLogEntry,
  Job,
  LogEntry,
  openLogs,
  Task,
  TaskLog,
  timestampToMoment,
} from './JobsPage'
import { TaskLogRow } from './TaskLogRow'

const useStyles = makeStylesFast(() => ({
  label: {
    fontWeight: 500,
  },
}))

type TaskDetailsProps = {
  job: Job | null
  task: Task
}

/**
 * Details about a single task execution.
 * Shows the task ID, trace ID, and logs.
 */
export default function TaskDetails({ job, task }: TaskDetailsProps) {
  const classes = useStyles()

  // Gather all logs by task ID
  const taskStart = useMemo(() => {
    if (job) {
      return timestampToMoment(job.startLog.timestamp)
    } else {
      return timestampToMoment(task.startLog.timestamp).subtract(5, 'minute')
    }
  }, [job, task.startLog.timestamp])
  const taskEnd = useMemo(() => taskStart.clone().add(1, 'day'), [taskStart])
  const logsFilter = useMemo(() => {
    return `
      resource.type = "cloud_run_revision"
      resource.labels.service_name = "cron"
      severity>=DEFAULT
      timestamp>="${taskStart.toISOString()}"
      timestamp<="${taskEnd.toISOString()}"
      "${task.id}"
    `
  }, [taskStart, taskEnd, task.id])

  const { data, loading } = useCloudLoggingLogsQuery({
    variables: {
      input: {
        filter: logsFilter,
        pageSize: 1000,
        orderBy: 'timestamp asc',
      },
    },
  })

  const cronLogs = useMemo(() => {
    const entries = (data?.cloudLoggingLogs.entries ?? []) as LogEntry[]
    return entries.map((entry): TaskLog => {
      return {
        log: entry,
      }
    })
  }, [data])

  const queuedLog = useMemo(() => {
    const log = cronLogs.find(({ log }) =>
      getMessageFromLogEntry(log)?.includes('Successfully queued task')
    )
    return log ?? null
  }, [cronLogs])

  const processingLog = useMemo(() => {
    const log = cronLogs.find(({ log }) => getMessageFromLogEntry(log)?.includes('Processing'))
    return log ?? null
  }, [cronLogs])

  const endLog = useMemo(() => {
    const processedLog = cronLogs.find(({ log }) =>
      getMessageFromLogEntry(log)?.includes('Processed task')
    )
    const failedLog = cronLogs.find(({ log }) =>
      getMessageFromLogEntry(log)?.includes('Failed to process task')
    )
    return processedLog ?? failedLog ?? null
  }, [cronLogs])

  const traceId = useMemo(() => {
    if (!processingLog) {
      return null
    }
    return processingLog.log['logging.googleapis.com/trace'] || null
  }, [processingLog])

  // Gather all logs by trace ID, which was extracted from the processing log
  const traceFilter = useMemo(() => {
    return `
      severity>=DEFAULT
      trace="${traceId}"
      timestamp>="${taskStart.toISOString()}"
      timestamp<="${taskEnd.toISOString()}"
    `
  }, [taskStart, taskEnd, traceId])

  const { data: traceData } = useCloudLoggingLogsQuery({
    variables: {
      input: {
        filter: traceFilter,
        pageSize: 1000,
        orderBy: 'timestamp asc',
      },
    },
    skip: !traceId,
  })

  const traceLogs = useMemo(() => {
    const entries = (traceData?.cloudLoggingLogs.entries ?? []) as LogEntry[]
    return entries.map((entry): TaskLog => {
      return { log: entry }
    })
  }, [traceData])

  const logs = useMemo(() => {
    const allLogs = [...cronLogs, ...traceLogs]
    const unique = _.uniqBy(allLogs, (log) => log.log['logging.googleapis.com/insertId'])
    const sorted = _.orderBy(unique, (log) => timestampToMoment(log.log.timestamp).valueOf())
    return sorted
  }, [cronLogs, traceLogs])

  const handleOpenTaskIdLogs = useCallback(() => openLogs({ filter: logsFilter }), [logsFilter])
  const handleOpenTraceLogs = useCallback(() => openLogs({ filter: traceFilter }), [traceFilter])

  return (
    <Grid2 container flexDirection="row" spacing={2}>
      <Grid2 size={4}>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Cron Task</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.label}>Queued at</TableCell>
                <TableCell>
                  {queuedLog &&
                    timestampToMoment(queuedLog.log.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.label}>Started at</TableCell>
                <TableCell>
                  {processingLog &&
                    timestampToMoment(processingLog.log.timestamp).format(
                      'YYYY-MM-DD HH:mm:ss.SSS'
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.label}>Ended at</TableCell>
                <TableCell>
                  {endLog &&
                    timestampToMoment(endLog.log.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.label}>Task ID</TableCell>
                <TableCell>
                  <Box
                    component="code"
                    sx={{
                      overflow: 'hidden',
                      width: 400,
                      fontSize: '0.8rem',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    {task.id}
                  </Box>
                  <IdentifierIconButton id={task.id} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.label}>Trace ID</TableCell>
                <TableCell>
                  <Box
                    component="code"
                    sx={{
                      overflow: 'hidden',
                      width: 400,
                      fontSize: '0.8rem',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    {traceId}
                  </Box>
                  <IdentifierIconButton id={traceId ?? ''} />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    startIcon={<FormatAlignLeftIcon />}
                    onClick={handleOpenTaskIdLogs}
                  >
                    Open logs for Task ID
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    startIcon={<FormatAlignLeftIcon />}
                    onClick={handleOpenTraceLogs}
                  >
                    Open logs for Trace ID
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid2>
      <Grid2 size={8}>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader sx={{ minWidth: '100%', tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 100 }}>Severity</TableCell>
                <TableCell sx={{ width: 200 }}>Time (PT)</TableCell>
                <TableCell>Log</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell>
                    <Skeleton variant="text" width={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={200} />
                  </TableCell>
                </TableRow>
              )}
              {logs.map((taskLog) => (
                <TaskLogRow
                  key={taskLog.log['logging.googleapis.com/insertId']}
                  taskLog={taskLog}
                />
              ))}
              {logs.length === 0 && !loading && (
                <TableRow>
                  <TableCell colSpan={3}>No logs found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </Grid2>
  )
}
