import { gql } from '@apollo/client'
import { Close, Download, InfoOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { MONTH_FORMAT, formatCentsToDollars, safeDivide } from 'siteline-common-all'
import {
  SitelineText,
  amount,
  blank,
  bold,
  colors,
  getColumnSizesFromRows,
  number,
  saveAs,
  string,
} from 'siteline-common-web'
import { CellObject, write } from 'xlsx'
import {
  BillingTotalsData,
  useBillingTotalsLazyQuery,
  useBillingTotalsQuery,
} from '../../common/graphql/apollo-operations'
import { getXAxisProps } from '../../common/util/Chart'
import { ChartTitleAndArrows } from './ChartTitleAndArrows'

gql`
  query billingTotals($input: BillingTotalsInput!) {
    billingTotals(input: $input) {
      month
      companyId
      companyCreatedAt
      companyName
      submittedPayAppCount
      totalBilledThisMonth
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

const compactNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short',
})

function getChartData(
  dataByCompany: Record<string, BillingTotalsData[]>,
  filterCompanyIds: string[],
  accountOwner: string | null,
  months: string[],
  chartMetric: ChartMetric | null,
  sortBy: SortBy | null
) {
  const companyData = Object.entries(dataByCompany)
    .filter(([, companyData]) => {
      const companyId = companyData.length > 0 ? companyData[0].companyId : ''
      return filterCompanyIds.length === 0 || filterCompanyIds.includes(companyId)
    })
    .filter(([, companyData]) => {
      const companyPointOfContactId =
        companyData.length > 0 ? companyData[0].sitelinePointOfContactId : ''
      return accountOwner ? companyPointOfContactId === accountOwner : true
    })
    .map(([companyName, companyData]) => {
      const barData: Record<string, string | number> = {
        companyName,
        companyCreatedAt: moment.utc(companyData[0].companyCreatedAt).unix(),
      }

      // For each month shown, include the number of pay apps submitted in that month. We also
      // store the number of pay apps submitted in each of the 3 months to calculate dropoff.
      const sortedMonths = _.orderBy(
        months,
        (month) => moment.utc(month, MONTH_FORMAT).unix(),
        'desc'
      )
      let latestMonthSubmitted = 0
      let previousMonthSubmitted = 0
      let firstMonthSubmitted = 0
      months.forEach((month) => {
        const monthData = companyData.find(
          (data) => moment.utc(data.month).format(MONTH_FORMAT) === month
        )
        barData[month] =
          chartMetric === 'payAppCount'
            ? (monthData?.submittedPayAppCount ?? 0)
            : (monthData?.totalBilledThisMonth ?? 0)

        if (month === sortedMonths[0]) {
          latestMonthSubmitted =
            chartMetric === 'payAppCount'
              ? (monthData?.submittedPayAppCount ?? 0)
              : (monthData?.totalBilledThisMonth ?? 0)
        } else if (month === sortedMonths[1]) {
          previousMonthSubmitted =
            chartMetric === 'payAppCount'
              ? (monthData?.submittedPayAppCount ?? 0)
              : (monthData?.totalBilledThisMonth ?? 0)
        } else if (month === sortedMonths[2]) {
          firstMonthSubmitted =
            chartMetric === 'payAppCount'
              ? (monthData?.submittedPayAppCount ?? 0)
              : (monthData?.totalBilledThisMonth ?? 0)
        }
      })

      // If there were no submissions in either month in the current sort by, exclude the company
      // from the chart entirely
      if (
        sortBy === 'dropoffCurrent' &&
        previousMonthSubmitted === 0 &&
        latestMonthSubmitted === 0
      ) {
        return null
      }
      if (
        sortBy === 'dropoffPrevious' &&
        firstMonthSubmitted === 0 &&
        previousMonthSubmitted === 0
      ) {
        return null
      }

      // Dropoff is the percent difference from last month to the current month
      const currentDropoff: number | undefined = safeDivide(
        previousMonthSubmitted - latestMonthSubmitted,
        previousMonthSubmitted,
        -1 * Infinity
      )
      const previousDropoff: number | undefined = safeDivide(
        firstMonthSubmitted - previousMonthSubmitted,
        firstMonthSubmitted,
        -1 * Infinity
      )

      barData['dropoffCurrent'] = currentDropoff
      barData['dropoffPrevious'] = previousDropoff

      return barData
    })

  return _.chain(companyData)
    .compact()
    .orderBy((data) => {
      switch (sortBy) {
        case 'companyName':
          return data['companyName']
        case 'customerAge':
          return -1 * Number(data['companyCreatedAt'])
        case 'dropoffCurrent':
          return -1 * Number(data['dropoffCurrent'])
        case 'dropoffPrevious':
          return -1 * Number(data['dropoffPrevious'])
        case null:
          return ''
      }
    })
    .value()
}

/** The number of months shown in the chart */
const NUM_MONTHS_SHOWN = 3
/** The number of companies shown on the chart at once */
export const NUM_COMPANIES_PER_CHART_PAGE = 8
export const CHART_ICON_FONT_SIZE = 32
const barColors = [colors.blue50, colors.green50, colors.red50]
/**
 * Allow sorting in 4 ways:
 * companyName: Alphabetical by company name, ascending
 * customerAge: Ordered by company creation date, with newest companies first
 * dropoffPrevious: Percentage dropoff in submissions from last month to this month, descending
 * dropoffCurrent: Percentage dropoff in submissions from two months ago to last month, descending
 */
type SortBy = 'companyName' | 'customerAge' | 'dropoffPrevious' | 'dropoffCurrent'
type ChartMetric = 'payAppCount' | 'amountBilled'

interface BillingTotalsDashboardProps {
  isSmallScreen: boolean
}

/** Dashboard for viewing billing totals by customer */
export function BillingTotalsDashboard({ isSmallScreen }: BillingTotalsDashboardProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const endMonth = moment.utc().subtract(1, 'months')
  const startMonth = endMonth.clone().subtract(NUM_MONTHS_SHOWN - 1, 'months')
  const { data, loading } = useBillingTotalsQuery({
    variables: {
      input: {
        startMonth: startMonth.format(MONTH_FORMAT),
        endMonth: endMonth.format(MONTH_FORMAT),
      },
    },
  })
  const [loadBillingTotalsForExport, { loading: loadingExportData }] = useBillingTotalsLazyQuery()
  const [startIndex, setStartIndex] = useState<number>(0)

  const sortBy = useMemo(() => {
    return queryString.parse(location.search).sortBy as SortBy | null
  }, [location.search])
  const handleSortByChange = useCallback(
    (sortBy: SortBy, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      search.set('sortBy', sortBy)
      navigate({ search: search.toString() }, { replace })
      setStartIndex(0)
    },
    [location.search, navigate]
  )

  // Default to sorting by company name
  useEffect(() => {
    if (!sortBy) {
      handleSortByChange('companyName', true)
    }
  }, [handleSortByChange, sortBy])

  const chartMetric = useMemo(() => {
    return queryString.parse(location.search).chartMetric as ChartMetric | null
  }, [location.search])
  const handleChartMetricChange = useCallback(
    (chartMetric: ChartMetric, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      search.set('chartMetric', chartMetric)
      navigate({ search: search.toString() }, { replace })
      setStartIndex(0)
    },
    [location.search, navigate]
  )

  // Default to viewing pay app amounts
  useEffect(() => {
    if (!chartMetric) {
      handleChartMetricChange('payAppCount', true)
    }
  }, [chartMetric, handleChartMetricChange])

  const accountOwner = useMemo(() => {
    return queryString.parse(location.search).accountOwner as string | null
  }, [location.search])
  const handleAccountOwnerChange = useCallback(
    (userId: string | null, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      if (userId) {
        search.set('accountOwner', userId)
      } else {
        search.delete('accountOwner')
      }
      navigate({ search: search.toString() }, { replace })
      setStartIndex(0)
    },
    [location.search, navigate]
  )

  const filterCompanyIds = useMemo(() => {
    const { companies } = queryString.parse(location.search)
    let companyIds: string[] = []
    if (typeof companies === 'string') {
      companyIds = [companies]
    } else if (Array.isArray(companies)) {
      companyIds = _.compact(companies)
    }
    return _.chain(companyIds)
      .flatMap((companyId) => companyId.split(','))
      .map(decodeURIComponent)
      .value()
  }, [location.search])
  const handleFilterCompanyIds = useCallback(
    (companyIds: string[]) => {
      const search = new URLSearchParams(location.search)
      if (companyIds.length > 0) {
        search.set('companies', companyIds.join(','))
      } else {
        search.delete('companies')
      }
      navigate({ search: search.toString() })
      setStartIndex(0)
    },
    [location.search, navigate]
  )

  const dataByCompany = useMemo(() => {
    const billingData = data?.billingTotals ?? []
    return _.groupBy(billingData, (data) => data.companyName)
  }, [data?.billingTotals])

  // The months to show in the chart (these are the last 3 months, including the current month)
  const months = useMemo(
    () =>
      _.range(NUM_MONTHS_SHOWN).map((index) =>
        startMonth.clone().add(index, 'months').format(MONTH_FORMAT)
      ),
    [startMonth]
  )

  const chartData = useMemo(
    () => getChartData(dataByCompany, filterCompanyIds, accountOwner, months, chartMetric, sortBy),
    [accountOwner, chartMetric, dataByCompany, filterCompanyIds, months, sortBy]
  )

  const handleExportData = useCallback(async () => {
    // For the Excel export, we load 6 months of data, including the current month only if it's past
    // the 25th
    const numExportMonths = 6
    const exportEndMonth =
      moment.utc().date() > 25 ? moment.utc() : moment.utc().subtract(1, 'months')
    const exportStartMonth = exportEndMonth.clone().subtract(numExportMonths - 1, 'months')
    const { data } = await loadBillingTotalsForExport({
      variables: {
        input: {
          startMonth: exportStartMonth.format(MONTH_FORMAT),
          endMonth: exportEndMonth.format(MONTH_FORMAT),
        },
      },
    })
    const exportDataByCompany = _.groupBy(data?.billingTotals ?? [], (data) => data.companyName)
    const exportMonths = _.range(numExportMonths).map((index) =>
      exportStartMonth.clone().add(index, 'months')
    )
    const exportData = getChartData(
      exportDataByCompany,
      filterCompanyIds,
      accountOwner,
      exportMonths.map((month) => month.format(MONTH_FORMAT)),
      chartMetric,
      sortBy
    )
    const rows: CellObject[][] = []
    rows.push(
      [string('Company'), ...exportMonths.map((month) => string(month.format('MMM YYYY')))].map(
        bold
      )
    )
    exportData.forEach((company) => {
      rows.push([
        string(String(company['companyName'])),
        ...exportMonths.map((month) => {
          const monthAmount = Number(company[month.format(MONTH_FORMAT)])
          if (isNaN(monthAmount)) {
            return blank()
          }
          return chartMetric === 'amountBilled' ? amount(monthAmount / 100) : number(monthAmount)
        }),
      ])
    })
    const { utils } = await import('xlsx-js-style')
    const sheet = utils.json_to_sheet(rows, { skipHeader: true })
    sheet['!cols'] = getColumnSizesFromRows(rows)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, sheet, 'Billing')
    const out = write(workbook, { type: 'array' })
    const blob = new Blob([out], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    })
    const filename = `Billing.xlsx`
    saveAs(blob, filename)
  }, [accountOwner, chartMetric, filterCompanyIds, loadBillingTotalsForExport, sortBy])

  const numCompaniesInChart = chartData.length
  const currentPageChartData = useMemo(
    () =>
      chartData.slice(
        startIndex,
        Math.min(startIndex + NUM_COMPANIES_PER_CHART_PAGE, numCompaniesInChart)
      ),
    [chartData, numCompaniesInChart, startIndex]
  )

  const companyInfo = useMemo(
    () =>
      _.chain(dataByCompany)
        .entries()
        .map(([companyName, companyData]) => {
          const companyId = companyData.length > 0 ? companyData[0].companyId : ''
          return { companyName, companyId }
        })
        .compact()
        .orderBy(({ companyName }) => companyName)
        .value(),
    [dataByCompany]
  )

  const accountOwners = useMemo(
    () =>
      _.chain(dataByCompany)
        .entries()
        .map(([, companyData]) => {
          if (companyData.length === 0) {
            return null
          }
          return {
            id: companyData[0].sitelinePointOfContactId,
            name: companyData[0].sitelinePointOfContactName,
          }
        })
        .compact()
        .filter(
          (pointOfContact): pointOfContact is { id: string; name: string } =>
            _.isString(pointOfContact.id) && _.isString(pointOfContact.name)
        )
        .uniqBy(({ name }) => name)
        .sortBy(({ name }) => name)
        .value(),
    [dataByCompany]
  )

  const amountBilledByMonth = useMemo(() => {
    if (chartMetric === 'amountBilled') {
      return months.map((month) => {
        const total = _.sumBy(chartData, (companyData) => Number(companyData[month]))
        return { month: moment.utc(month, MONTH_FORMAT).format('MMMM YYYY'), total }
      })
    }
    return []
  }, [chartData, chartMetric, months])

  // Show the total amounts billed if:
  // 1. We're viewing the amount billed metric, and data has loaded
  // 2. We're not viewing a dropoff view, which would exclude certain companies that don't have
  //    data in the relevant months (we hide the totals in this case so a user doesn't accidentally
  //    assume the total includes all companies)
  const shouldShowTotalBilled = useMemo(
    () =>
      amountBilledByMonth.length > 0 && sortBy && ['companyName', 'customerAge'].includes(sortBy),
    [amountBilledByMonth.length, sortBy]
  )

  const {
    labelLength: xAxisLabelLength,
    xAxisHeight,
    tickAngle,
    labelAnchor: xAxisLabelAnchor,
  } = useMemo(() => getXAxisProps(isSmallScreen), [isSmallScreen])

  return (
    <>
      <ChartTitleAndArrows
        title={
          <>
            <FormControl>
              <InputLabel id="chartMetric">Metric</InputLabel>
              <Select
                // Needed to trigger a re-mount when the metric is initially set, so the Select
                // component updates with the correct value
                key={chartMetric}
                labelId="chartMetric"
                value={chartMetric}
                label="Metric"
                onChange={(ev) => {
                  handleChartMetricChange(ev.target.value as ChartMetric)
                }}
              >
                <MenuItem value="payAppCount">Pay apps submitted</MenuItem>
                <MenuItem value="amountBilled">Amount billed</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="sortBy">Sort by</InputLabel>
              <Select
                // Needed to trigger a re-mount when the sortBy value is initially set, so the Select
                // component updates with the correct value
                key={sortBy}
                labelId="sortBy"
                value={sortBy}
                label="Sort by"
                onChange={(ev) => {
                  handleSortByChange(ev.target.value as SortBy)
                }}
              >
                <MenuItem value="companyName">Company name</MenuItem>
                <MenuItem value="customerAge">Customer age</MenuItem>
                <MenuItem value="dropoffCurrent">
                  Dropoff ({moment.utc().subtract(1, 'month').format('MMM')} to{' '}
                  {moment.utc().format('MMM')})
                </MenuItem>
                <MenuItem value="dropoffPrevious">
                  Dropoff ({moment.utc().subtract(2, 'months').format('MMM')} to{' '}
                  {moment.utc().subtract(1, 'month').format('MMM')})
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="companySelect">
                {filterCompanyIds.length > 0 ? 'Companies' : 'Filter by company'}
              </InputLabel>
              <Select
                style={{ width: 300 }}
                labelId="companySelect"
                value={filterCompanyIds}
                label={filterCompanyIds.length > 0 ? 'Companies' : 'Filter by company'}
                onChange={(ev) => {
                  handleFilterCompanyIds(ev.target.value as string[])
                }}
                multiple
                renderValue={() => `${filterCompanyIds.length} selected`}
                endAdornment={
                  filterCompanyIds.length > 0 ? (
                    <IconButton
                      onClick={() => handleFilterCompanyIds([])}
                      sx={{ marginRight: 1.5 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  ) : undefined
                }
              >
                {companyInfo.map(({ companyName, companyId }) => (
                  <MenuItem key={companyId} value={companyId}>
                    {companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="accountOwner">Account owner</InputLabel>
              <Select
                key={accountOwner}
                style={{ minWidth: 150 }}
                labelId="accountOwner"
                value={accountOwner ?? 'All'}
                label="Account owner"
                onChange={(ev) => {
                  handleAccountOwnerChange(ev.target.value as string)
                }}
                endAdornment={
                  accountOwner ? (
                    <IconButton
                      onClick={() => handleAccountOwnerChange(null)}
                      sx={{ marginRight: 1.5 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  ) : undefined
                }
              >
                <MenuItem value="All">All</MenuItem>
                {accountOwners.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
        startIndex={startIndex}
        onStartIndexChange={setStartIndex}
        numCompaniesInChart={numCompaniesInChart}
        numCompaniesPerPage={NUM_COMPANIES_PER_CHART_PAGE}
      />
      {!loading && (
        <>
          <ResponsiveContainer height={500} width="100%" style={{ marginTop: 16 }}>
            <BarChart data={currentPageChartData} margin={{ bottom: 32, left: 80, right: 32 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="companyName"
                tick={{ fontSize: 12 }}
                interval={0}
                tickFormatter={(companyName) =>
                  _.truncate(companyName, { length: xAxisLabelLength })
                }
                angle={tickAngle}
                height={xAxisHeight}
                textAnchor={xAxisLabelAnchor}
              />
              <YAxis
                tickFormatter={
                  chartMetric === 'amountBilled'
                    ? (value) => compactNumberFormat.format(value / 100)
                    : undefined
                }
              >
                <Label
                  angle={-90}
                  value={chartMetric === 'payAppCount' ? 'Pay apps submitted' : 'Amount billed'}
                  offset={48}
                  position="left"
                  orientation="vertical"
                />
              </YAxis>
              <ChartTooltip
                formatter={
                  chartMetric === 'amountBilled'
                    ? (value: number) => formatCentsToDollars(value, true)
                    : undefined
                }
              />
              {months.map((month, index) => (
                <Bar
                  key={month}
                  dataKey={month}
                  fill={barColors[index]}
                  name={moment.utc(month, MONTH_FORMAT).format('MMMM')}
                />
              ))}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
          {shouldShowTotalBilled && (
            <div style={{ marginTop: 16 }}>
              <SitelineText
                variant="body1"
                bold
                endIcon={
                  <Tooltip title="Amounts include progress and stored materials billed. Retention is not deducted.">
                    <InfoOutlined style={{ fontSize: 16 }} />
                  </Tooltip>
                }
                style={{ marginBottom: 8 }}
              >
                Billing totals
                {filterCompanyIds.length > 0 ? (
                  <span style={{ color: colors.grey50, display: 'block', marginLeft: 8 }}>
                    ({filterCompanyIds.length} companies)
                  </span>
                ) : (
                  ''
                )}
              </SitelineText>
              {amountBilledByMonth.map(({ month, total }) => (
                <SitelineText key={month} variant="body1">
                  {month}:{' '}
                  <span style={{ fontWeight: 600 }}>{formatCentsToDollars(total, true)}</span>
                </SitelineText>
              ))}
            </div>
          )}
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={handleExportData}
            style={{ marginTop: 16 }}
            startIcon={<Download />}
            loading={loadingExportData}
          >
            Export monthly billing
          </LoadingButton>
        </>
      )}
      {loading && (
        <div
          style={{
            height: 500,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  )
}
