import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { SxProps } from '@mui/material'
import moment from 'moment-timezone'
import { colors, SitelineTooltip } from 'siteline-common-web'
import { FormTemplateProperties } from '../graphql/apollo-operations'

type TemplateInfoIconProps = {
  template: Pick<FormTemplateProperties, 'createdAt'>
  sx?: SxProps
}

/**
 * An info icon that shows the date the template was uploaded on hover.
 */
export function TemplateInfoIcon({ template, sx }: TemplateInfoIconProps) {
  const uploadedAt = moment.tz(template.createdAt, moment.tz.guess())
  return (
    <SitelineTooltip
      title={`Uploaded on ${uploadedAt.format('MM/DD/YYYY')} at ${uploadedAt.format('h:mm a')}`}
      placement="top"
      arrow
    >
      <InfoOutlinedIcon fontSize="small" htmlColor={colors.grey50} sx={sx} />
    </SitelineTooltip>
  )
}
