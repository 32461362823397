import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import { TableCell, TableRow, Typography } from '@mui/material'
import _ from 'lodash'
import { colors } from 'siteline-common-web'
import { Task } from './JobsPage'

type TaskRowProps = {
  task: Task
  selected: boolean
  onClick: () => void
  includeJobName: boolean
}

/**
 * A task row in the task list.
 * Represents a single task execution.
 */
export function TaskRow({ task, onClick, selected, includeJobName }: TaskRowProps) {
  return (
    <TableRow
      hover
      key={task.startLog['logging.googleapis.com/insertId']}
      selected={selected}
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>
        {task.startLog.severity === 'INFO' ? (
          <CheckIcon htmlColor={colors.green40} fontSize="small" />
        ) : (
          <ErrorIcon htmlColor={colors.red50} fontSize="small" />
        )}
      </TableCell>
      {includeJobName && (
        <TableCell>
          <Typography variant="body2" sx={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>
            {task.jobName}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography variant="body2" sx={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>
          {task.prefix}
        </Typography>
      </TableCell>
      <TableCell>
        {_.map(task.metadata, (value, key) => {
          return (
            <Typography
              variant="body2"
              sx={{ fontFamily: 'monospace', fontSize: '0.8rem' }}
              key={key}
            >
              {key}: {value}
            </Typography>
          )
        })}
      </TableCell>
    </TableRow>
  )
}
