import { TableCell, TableRow, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { Job } from './JobsPage'

type NewType = {
  job: Job
  selected: boolean
  onClick: () => void
}

/**
 * A job row in the job list.
 * Represents a single cron job execution.
 */
export function JobRow({ job, onClick, selected }: NewType) {
  return (
    <TableRow
      hover
      key={job.startLog['logging.googleapis.com/insertId']}
      selected={selected}
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {moment
          .tz(job.startLog.timestamp.seconds * 1000, 'America/Los_Angeles')
          .format('M/DD h:mma')}
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>
          {job.name}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
