import { gql } from '@apollo/client'
import {
  GetCompanyForDetailsQuery,
  GetContractQuery,
  GetPayAppForDetailsQuery,
  GetProjectForDetailsQuery,
  GetUserForDetailsQuery,
  PaginatedTemplatesQuery,
} from './apollo-operations'

export type DetailedCompany = GetCompanyForDetailsQuery['company']
export type DetailedPayApp = GetPayAppForDetailsQuery['payApp']
export type DetailedPayAppContract = GetPayAppForDetailsQuery['payApp']['contract']
export type DetailedProject = GetProjectForDetailsQuery['project']
export type DetailedUser = GetUserForDetailsQuery['user']
export type Contract = GetContractQuery['contract']
export type Integration = Contract['integrations'][number]
export type ListFormTemplate = PaginatedTemplatesQuery['paginatedTemplates']['templates'][number]

export const location = gql`
  fragment LocationProperties on Location {
    id
    createdAt
    nickname
    street1
    street2
    city
    county
    state
    country
    postalCode
    timeZone
    coordinates
  }
`

export const company = gql`
  fragment CompanyProperties on Company {
    id
    name
    nickname
    type
    phoneNumber
    expectedNumberOfProjects
    defaultShowAuditItemOnSignedPackages
    allowLumpSumOverbilling
    notaryTags
    storedMaterialsCarryoverType
    isDemoCompany
    picture {
      id
      url
    }
    notaryStamp {
      id
      url
    }
    notarySignature {
      id
      url
    }
    secondarySignature {
      id
      url
    }
    locations {
      ...LocationProperties
    }
    metadata {
      subtrade
      domains
      domainSignup
      monthlyCreatePayAppReminderDate
    }
    defaultQuickBillingTemplate {
      id
    }
    sitelinePointOfContact {
      id
      email
    }
  }
  ${location}
`

export const companyIntegration = gql`
  fragment CompanyIntegrationProperties on CompanyIntegration {
    id
    createdAt
    type
    label
    shortName
    longName
    credentialsUpdatedAt
    credentialsReadyForAutoRotation
    archivedAt
    shouldImportNewProjectSov
    metadata
    importChangeOrdersMethod
    importComboJobMethod
    importProjectNameSource
    revertPayAppsToDraftWhenTexturaInvoiceRejected
    location {
      ...LocationProperties
    }
  }
  ${location}
`

export const minimalUser = gql`
  fragment MinimalUserProperties on User {
    id
    firstName
    lastName
    email
    jobTitle
    phoneNumber
    companies {
      id
      name
    }
  }
`

export const user = gql`
  fragment UserProperties on User {
    ...MinimalUserProperties
    blockedEmails
    blockedNotifications
    policiesAcceptedAt
    companies {
      ...CompanyProperties
    }
    companyUsers {
      id
      status
      role
      company {
        ...CompanyProperties
      }
    }
  }
  ${company}
  ${minimalUser}
`

export const minimalCompanyUser = gql`
  fragment MinimalCompanyUserProperties on CompanyUser {
    id
    role
    user {
      ...MinimalUserProperties
    }
  }
  ${minimalUser}
`

export const companyUser = gql`
  fragment CompanyUserProperties on CompanyUser {
    id
    role
    isSitelineAdmin
    status
    permissions
    user {
      ...UserProperties
    }
  }
  ${user}
`

export const storedFile = gql`
  fragment StoredFileProperties on StoredFile {
    id
    type
    url
    name
  }
`

export const sov = gql`
  fragment SovProperties on Sov {
    id
    totalValue
    totalBilled
    totalRetention
    progressComplete
  }
`

export const sovLineItemGroup = gql`
  fragment SovLineItemGroupProperties on SovLineItemGroup {
    id
    name
    code
  }
`

export const sovLineItem = gql`
  fragment SovLineItemProperties on SovLineItem {
    id
    code
    sortOrder
    name
    isChangeOrder
    changeOrderApprovedDate
    latestTotalValue
    totalBilled
    previousBilled
    previousStoredMaterials
    totalRetention
    progressComplete
    defaultRetentionPercent
    sovLineItemGroup {
      ...SovLineItemGroupProperties
    }
  }
  ${sovLineItemGroup}
`

export const companySov = gql`
  fragment CompanySovProperties on Sov {
    ...SovProperties
    contract {
      id
      timeZone
    }
    lineItems {
      ...SovLineItemProperties
    }
  }
  ${sov}
  ${location}
  ${sovLineItem}
`

export const sovLineItemProgress = gql`
  fragment SovLineItemProgressProperties on SovLineItemProgress {
    id
    currentBilled
    progressBilled
    currentRetention
    previousBilled
    storedMaterialBilled
    previousRetention
    previousRetentionBilled
    totalValue
    sovLineItem {
      ...SovLineItemProperties
    }
    updatedBy {
      ...UserProperties
    }
  }
  ${sovLineItem}
  ${user}
`

export const formTemplateAnnotation = gql`
  fragment FormTemplateAnnotationProperties on FormTemplateAnnotation {
    id
    permanentId
    syncTag
    userVisibleName
    type
    xStart
    yStart
    width
    height
    pageNumber
    fontFamily
    fontColor
    textAlignment
    wrapText
    isOptional
    selectedKey
    defaultValueKey
    prefix
    suffix
    fieldType
    doNotRetainOnReset
    dynamicFieldTag
    copyDefaultValueFromPreviousAnnotationValue
    imageType
    signatureType
  }
`

export const formTemplateStatusLog = gql`
  fragment FormTemplateStatusLogProperties on FormTemplateStatusLog {
    id
    statusUpdatedAt
    status
    user {
      id
      email
    }
  }
`

export const formTemplateVersion = gql`
  fragment FormTemplateVersionProperties on FormTemplateVersion {
    id
    createdAt
    updatedAt
    file {
      ...StoredFileProperties
    }
    annotations {
      ...FormTemplateAnnotationProperties
    }
    versionNumber
    isReadyForManualStoredMaterials
  }
  ${storedFile}
  ${formTemplateAnnotation}
`

export const formTemplateComment = gql`
  fragment FormTemplateCommentProperties on FormTemplateComment {
    id
    createdAt
    message
    isSystemGenerated
    user {
      id
      firstName
      lastName
      email
    }
  }
`

export const formTemplate = gql`
  fragment FormTemplateProperties on FormTemplate {
    id
    createdAt
    type
    status
    statusLogs {
      ...FormTemplateStatusLogProperties
    }
    skippedValidation
    userVisibleName
    isCustomerReady
    tags
    dueDate
    associatedCompany {
      id
    }
    requestingCompany {
      id
    }
    associatedContracts {
      id
      providedAsFormType
      contract {
        id
      }
    }
    owner {
      id
      email
      firstName
      lastName
    }
    builder {
      id
      email
      firstName
      lastName
    }
    validator {
      id
      email
      firstName
      lastName
    }
    versions {
      ...FormTemplateVersionProperties
    }
    variants {
      id
      internalName
      isDefaultVariant
    }
    comments {
      ...FormTemplateCommentProperties
    }
    originalFile {
      ...StoredFileProperties
    }
    duplicateTemplate {
      id
      userVisibleName
    }
  }
  ${formTemplateVersion}
  ${formTemplateStatusLog}
  ${formTemplateComment}
  ${storedFile}
`

export const minimalFormTemplate = gql`
  fragment MinimalFormTemplateProperties on FormTemplate {
    id
    createdAt
    isCustomerReady
    userVisibleName
    skippedValidation
    type
    status
    versions {
      ...FormTemplateVersionProperties
    }
    variants {
      id
      internalName
      isDefaultVariant
    }
  }
  ${formTemplateVersion}
`

export const annotationOverride = gql`
  fragment AnnotationOverrideProperties on AnnotationOverride {
    id
    annotationPermanentId
    userVisibleName
    fontFamily
    fontColor
    textAlignment
    wrapText
    isOptional
    selectedKey
    defaultValueKey
    prefix
    suffix
    fieldType
    doNotRetainOnReset
    copyDefaultValueFromPreviousAnnotationValue
    imageType
    signatureType
    syncTag
  }
`

export const formTemplateVariant = gql`
  fragment FormTemplateVariantProperties on FormTemplateVariant {
    id
    internalName
    userVisibleName
    isDefaultVariant
    hidesZeroDollarAmounts
    roundPercentages
    useCompanyNotarySignatureIfAvailable
    annotationOverrides {
      ...AnnotationOverrideProperties
    }
  }
  ${annotationOverride}
`

export const similarTemplate = gql`
  fragment SimilarTemplateProperties on SimilarTemplate {
    template {
      ...FormTemplateProperties
    }
    version {
      ...FormTemplateVersionProperties
    }
    score
  }
  ${formTemplate}
  ${formTemplateVersion}
`

export const payAppNoProgress = gql`
  fragment PayAppNoProgressProperties on PayApp {
    id
    createdAt
    timeZone
    status
    retentionOnly
    billingStart
    billingEnd
    payAppDueDate
    currentBilled
    currentRetention
    totalRetention
    previousRetentionBilled
    contract {
      id
      company {
        ...CompanyProperties
      }
    }
  }
  ${company}
  ${formTemplateVersion}
`

export const amountLineItem = gql`
  fragment AmountLineItemProperties on AmountLineItem {
    id
    description
    amount
    sortOrder
    feePercent
  }
`

export const payApp = gql`
  fragment PayAppProperties on PayApp {
    ...PayAppNoProgressProperties
    progress {
      ...SovLineItemProgressProperties
    }
    amountLineItems {
      ...AmountLineItemProperties
    }
  }
  ${payAppNoProgress}
  ${sovLineItemProgress}
  ${amountLineItem}
`

export const companyProjectMetadata = gql`
  fragment CompanyProjectMetadataProperties on CompanyProjectMetadata {
    role
    companyName
    company {
      ...CompanyProperties
    }
    selectedAddress {
      ...LocationProperties
    }
  }
  ${company}
  ${location}
`

export const project = gql`
  fragment ProjectProperties on Project {
    id
    name
    projectNumber
    location {
      ...LocationProperties
    }
    generalContractor {
      ...CompanyProjectMetadataProperties
    }
  }
  ${location}
  ${companyProjectMetadata}
`

export const integration = gql`
  fragment IntegrationProperties on Integration {
    id
    createdAt
    type
    credentialsUpdatedAt
    mappings
    isActive
    shortName
    longName
    companyIntegration {
      id
    }
  }
`

export const legalDocument = gql`
  fragment LegalDocumentProperties on LegalDocument {
    id
    name
    periodStart
    periodEnd
    lastSyncIfSuccessful {
      id
      createdAt
    }
  }
`

export const legalRequirement = gql`
  fragment LegalRequirementProperties on LegalRequirement {
    id
    createdAt
    name
    timeZone
    expirationFrequency
    startDate
    endDate
    isVendorRequirement
    documents {
      id
    }
    formTemplate {
      id
    }
  }
`

export const payAppRequirement = gql`
  fragment PayAppRequirementProperties on PayAppRequirement {
    id
    templateVariant {
      id
      internalName
      userVisibleName
      template {
        id
        isCustomerReady
      }
    }
    groupOrder
    conditions
  }
`

export const payAppRequirementGroup = gql`
  fragment PayAppRequirementGroupProperties on PayAppRequirementGroup {
    id
    order
    payAppRequirements {
      ...PayAppRequirementProperties
    }
  }
  ${payAppRequirement}
`

export const lienWaiverFormTemplateVariant = gql`
  fragment LienWaiverFormTemplateVariantProperties on FormTemplateVariant {
    id
    template {
      ...FormTemplateProperties
    }
  }
  ${formTemplate}
`

export const subcontractorLienWaiverTemplateSet = gql`
  fragment SubcontractorLienWaiverTemplateSetProperties on SubcontractorLienWaiverTemplateSet {
    id
    conditionalFinalVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
    conditionalProgressVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
    unconditionalFinalVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
    unconditionalProgressVariant {
      ...LienWaiverFormTemplateVariantProperties
    }
  }
  ${lienWaiverFormTemplateVariant}
`

export const contract = gql`
  fragment ContractProperties on Contract {
    id
    role
    status
    timeZone
    contractDate
    contractNumber
    storedMaterialsCarryoverType
    project {
      ...ProjectProperties
    }
    company {
      ...CompanyProperties
    }
    sov {
      ...CompanySovProperties
    }
    defaultRetentionPercent
    retentionTrackingLevel
    daysBeforePayAppDue
    billingType
    contractTemplateVariables
    lienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
    lowerTierLienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
    internalProjectNumber
    vendorNumber
    pastPayAppCount
    payAppRequirementGroups {
      ...PayAppRequirementGroupProperties
    }
    legalRequirements {
      ...LegalRequirementProperties
    }
    selectedAddress {
      ...LocationProperties
    }
    changeOrderRequestTemplate {
      id
    }
    changeOrderLogTemplate {
      id
    }
    roundRetention
    integrations {
      ...IntegrationProperties
    }
    onboardedStatus {
      selectedPayAppForms
      onboardedPayAppForms
      selectedPrimaryLienWaivers
      onboardedPrimaryLienWaiverForms
      selectedVendorLienWaivers
      onboardedVendorLienWaiverForms
      selectedChangeOrderRequestForms
      onboardedChangeOrderRequestForms
      addedSov
      selectedRateTable
      addedTeammates
      addedGcContacts
      startedBilling
      notifiedOnboardedForms
    }
  }
  ${payAppRequirementGroup}
  ${legalRequirement}
  ${location}
  ${formTemplate}
  ${subcontractorLienWaiverTemplateSet}
  ${location}
  ${companySov}
  ${integration}
  ${project}
`

export const projectForDetails = gql`
  fragment ProjectForDetailsProperties on Project {
    id
    name
    projectNumber
    location {
      ...LocationProperties
    }
    owner {
      ...CompanyProjectMetadataProperties
      company {
        id
        users {
          ...MinimalCompanyUserProperties
        }
      }
    }
    generalContractor {
      ...CompanyProjectMetadataProperties
      company {
        id
        users {
          ...MinimalCompanyUserProperties
        }
      }
    }
    architect {
      ...CompanyProjectMetadataProperties
    }
    contracts {
      ...ContractProperties
      company {
        id
        users {
          ...MinimalCompanyUserProperties
        }
      }
      users {
        id
        user {
          ...MinimalUserProperties
        }
      }
    }

    metadata {
      fieldProgressDueOnDayOfMonth
      payAppDueOnDayOfMonth
    }
  }
  ${location}
  ${companyProjectMetadata}
  ${minimalUser}
  ${minimalCompanyUser}
  ${contract}
`

export const companyContact = gql`
  fragment CompanyContactProperties on CompanyContact {
    id
    fullName
    email
    companyName
    archivedAt
  }
`

export const vendor = gql`
  fragment VendorProperties on Vendor {
    id
    name
    contacts {
      id
      fullName
      email
      archivedAt
    }
  }
`

export const companyPayAppFormTemplateSet = gql`
  fragment CompanyPayAppFormTemplateSetProperties on CompanyPayAppFormTemplateSet {
    id
    name
    state
    billingType
    generalContractor {
      id
      name
    }
    payAppRequirementGroups {
      ...PayAppRequirementGroupProperties
    }
  }
  ${payAppRequirementGroup}
`

export const companyLienWaiverTemplateSet = gql`
  fragment CompanyLienWaiverTemplateSetProperties on CompanyLienWaiverTemplateSet {
    id
    name
    state
    generalContractor {
      id
      name
    }
    lienWaivers {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
  }
  ${subcontractorLienWaiverTemplateSet}
`

export const companyFormTemplateCollection = gql`
  fragment CompanyFormTemplateCollectionProperties on CompanyFormTemplateCollection {
    id
    name
    state
    billingType
    generalContractor {
      id
      name
    }
    payAppForms {
      ...CompanyPayAppFormTemplateSetProperties
    }
    primaryLienWaivers {
      ...CompanyLienWaiverTemplateSetProperties
    }
    vendorLienWaivers {
      ...CompanyLienWaiverTemplateSetProperties
    }
    changeOrderRequest {
      id
      internalName
      isDefaultVariant
      template {
        ...MinimalFormTemplateProperties
      }
    }
    changeOrderLog {
      id
      internalName
      isDefaultVariant
      template {
        ...MinimalFormTemplateProperties
      }
    }
  }
  ${companyPayAppFormTemplateSet}
  ${companyLienWaiverTemplateSet}
  ${minimalFormTemplate}
`

export const companyForDetails = gql`
  fragment CompanyForDetailsProperties on Company {
    ...CompanyProperties
    companyIntegrations {
      ...CompanyIntegrationProperties
    }
    users {
      ...CompanyUserProperties
    }
    vendors {
      ...VendorProperties
    }
    contacts {
      ...CompanyContactProperties
    }
    formTemplateCollections {
      ...CompanyFormTemplateCollectionProperties
    }
    payAppFormTemplateSets {
      ...CompanyPayAppFormTemplateSetProperties
    }
    primaryLienWaiverTemplateSets {
      ...CompanyLienWaiverTemplateSetProperties
    }
    vendorLienWaiverTemplateSets {
      ...CompanyLienWaiverTemplateSetProperties
    }
    defaultChangeOrderRequestTemplate {
      id
    }
    defaultChangeOrderLogTemplate {
      id
    }
  }
  ${company}
  ${companyUser}
  ${vendor}
  ${companyContact}
  ${companyIntegration}
  ${companyFormTemplateCollection}
  ${companyPayAppFormTemplateSet}
  ${companyLienWaiverTemplateSet}
`

export const userEducationCampaign = gql`
  fragment UserEducationCampaignProperties on UserEducationCampaign {
    id
    name
    startDate
    endDate
    targetPermissions
    showNewTag
    title
    message
    buttonLabel
    buttonLink
  }
`
