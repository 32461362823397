import { Chip, Link, styled } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { colors, FormTemplateType, Row, SitelineText, SitelineTooltip } from 'siteline-common-web'
import {
  AutocompletedVariant,
  VariantAutocomplete,
} from '../../common/components/VariantAutocomplete'

const StyledSelectFormForCollectionText = styled(SitelineText)(() => ({
  '& .link': {
    color: colors.grey90,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: colors.green70,
    },
  },
  '& .processingChip': {
    backgroundColor: colors.yellow20,
    color: colors.yellow50,
    cursor: 'default',
  },
}))

const StyledSelectFormForCollection = styled('div')(() => ({
  position: 'relative',
  width: 400,
}))

interface SelectFormForCollectionProps {
  isEditing: boolean
  selectedFormTemplateVariant: AutocompletedVariant | null
  onSelectFormTemplateVariant: (formTemplateVariant: AutocompletedVariant | null) => void
  formTemplateType: FormTemplateType.CHANGE_ORDER_LOG | FormTemplateType.CHANGE_ORDER_REQUEST
  isProcessingForms: boolean
}

export function SelectFormForCollection({
  isEditing,
  selectedFormTemplateVariant,
  onSelectFormTemplateVariant,
  formTemplateType,
  isProcessingForms,
}: SelectFormForCollectionProps) {
  if (!isEditing && !selectedFormTemplateVariant) {
    return (
      <SitelineText variant="body1" bold>
        -
      </SitelineText>
    )
  }

  if (!isEditing && !!selectedFormTemplateVariant) {
    return (
      <StyledSelectFormForCollectionText variant="body1" bold>
        <Row gap={8}>
          <Link
            component={RouterLink}
            to={`/templates/${selectedFormTemplateVariant.template.id}`}
            className="link"
          >
            {selectedFormTemplateVariant.template.userVisibleName}
          </Link>
          {isProcessingForms && (
            <SitelineTooltip title="This form template is still being built">
              <Chip label="Processing" size="small" className="processingChip" />
            </SitelineTooltip>
          )}
        </Row>
      </StyledSelectFormForCollectionText>
    )
  }

  return (
    <StyledSelectFormForCollection>
      <VariantAutocomplete
        variant={selectedFormTemplateVariant}
        onVariantChange={onSelectFormTemplateVariant}
        templateType={formTemplateType}
      />
    </StyledSelectFormForCollection>
  )
}
