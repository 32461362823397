import _ from 'lodash'
import { CellObject, type ColInfo } from 'xlsx-js-style'

/**
 * Returns a cell with the provided text as bold.
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */

export function bold(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      font: { bold: true },
    },
  })
} /**
 * Returns a cell with the provided text as italic.
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */

export function italic(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      font: { italic: true },
    },
  })
} /**
 * Returns a cell with the provided text right-aligned
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */

export function rightAlign(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      alignment: { horizontal: 'right' },
    },
  })
} /**
 * Returns a cell with the provided text left-aligned
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */

export function leftAlign(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      alignment: { horizontal: 'left' },
    },
  })
} /**
 * Returns a blank cell.
 * See https://docs.sheetjs.com/docs/csf/cell
 */

export function blank(): CellObject {
  return { t: 'z' }
} /**
 * Returns a simple string cell.
 * See https://docs.sheetjs.com/docs/csf/cell
 */

export function string(str: string): CellObject {
  return {
    t: 's',
    v: str,
    s: {
      alignment: { vertical: 'top' },
    },
  }
} /**
 * Returns a simple string cell that wraps its text.
 * See https://docs.sheetjs.com/docs/csf/cell
 */

export function wrap(str: string): CellObject {
  return {
    t: 's',
    v: str,
    s: {
      alignment: { wrapText: true, vertical: 'top' },
    },
  }
} /**
 * Returns a simple number cell
 * See https://docs.sheetjs.com/docs/csf/cell
 */

export function number(num: number): CellObject {
  return {
    t: 'n',
    v: num,
    s: {
      alignment: { vertical: 'top' },
    },
  }
} /**
 * Returns a formatted amount cell
 * See https://docs.sheetjs.com/docs/csf/cell
 */

export function amount(amount: number | null): CellObject {
  if (amount === null) {
    return blank()
  }
  return {
    t: 'n',
    v: amount,
    z: '$#,##0.00',
    s: {
      alignment: { vertical: 'top' },
    },
  }
} /**
 * Returns a formatted percent cell.
 * See https://docs.sheetjs.com/docs/csf/cell
 */

export function percent(percent: number | null, precision: number = 1): CellObject {
  if (percent === null) {
    return blank()
  }
  const decimalFormat = _.repeat('0', precision)
  const format = `0.${decimalFormat}%`
  return {
    t: 'n',
    v: percent,
    z: format,
    s: {
      alignment: { vertical: 'top' },
    },
  }
} /**
 * Takes an array of rows and returns an array of column sizes, extracted by looking at the longest
 * value per column.
 */

export function getColumnSizesFromRows(rows: CellObject[][]): ColInfo[] {
  const colCount = _.max(rows.map((row) => row.length))
  const colSizes = _.range(colCount ?? 0).map((colIndex) => {
    // Other columns depend on the longest value in the column
    const maxLength = _.max(
      rows.map((row) => {
        const value = row[colIndex] ?? ''

        // Extract string or number value from cell
        const cellValue = _.isString(value) || _.isNumber(value) ? value : value.v
        if (!cellValue) {
          return 0
        }

        // If cell is a string, return its length
        if (_.isString(cellValue)) {
          return cellValue.length

          // If cell is a number, add extra characters to account for currency formatting
        } else {
          return String(cellValue).length + 8
        }
      })
    )

    const maxColSize = 60
    const max = Math.min(maxLength ?? 0, maxColSize)

    // First column has a minimum width of 20 and max of 60
    if (colIndex === 0) {
      const firstColMinSize = 20
      return { wch: Math.max(max, firstColMinSize) }

      // Other columns have no minimum width, but they have a max of 60
    } else {
      return { wch: max }
    }
  })
  return colSizes
}
