import { Grid2 } from '@mui/material'
import CompanyCard from '../../common/components/CompanyCard'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { CompanyDetailsCompanyContacts } from './CompanyContacts'
import { CompanyDetailsChangeOrderLog } from './CompanyDetailsChangeOrderLog'
import { CompanyDetailsChangeOrderRequest } from './CompanyDetailsChangeOrderRequest'
import { CompanyDetailsFormSets } from './CompanyDetailsFormSets'
import { CompanyDetailsProjectFormCollections } from './CompanyDetailsProjectFormCollections'
import { CompanyIntegrations } from './CompanyIntegrations'
import CompanyLocations from './CompanyLocations'
import { CompanyNotesCard } from './CompanyNotes'
import CompanyProjects from './CompanyProjects'
import CompanyUsers from './CompanyUsers'
import { CompanyDetailsVendorContacts } from './CompanyVendorContacts'
import CompanyDetailsVendors from './CompanyVendors'

interface CompanyDetailsContentProps {
  company: DetailedCompany
}

export default function CompanyDetailsContent({ company }: CompanyDetailsContentProps) {
  return (
    <>
      <Grid2 size={{ xs: 5 }}>
        <CompanyCard company={company} withLink={false} withActions={true} readOnly={false} />
      </Grid2>
      <Grid2 size={{ xs: 7 }}>
        <CompanyNotesCard company={company} />
        <CompanyLocations company={company} />
        <CompanyIntegrations company={company} />
        <CompanyDetailsChangeOrderRequest company={company} />
        <CompanyDetailsChangeOrderLog company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyDetailsFormSets company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyDetailsProjectFormCollections company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyDetailsVendors company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyDetailsVendorContacts company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyDetailsCompanyContacts company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyUsers company={company} />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <CompanyProjects company={company} />
      </Grid2>
    </>
  )
}
