import { tlds } from '@hapi/tlds'
import { validate as validateEmail } from 'email-validator'
import _ from 'lodash'

export const MAX_COMMENT_LENGTH = 10_000
export const MAX_NOTES_LENGTH = 10_000
export const MAX_ATTACHMENT_NOTES_LENGTH = 1_000
export const MAX_LINE_ITEM_NAME_LENGTH = 1024
export const MAX_VENDOR_NUMBER_LENGTH = 100
export const MAX_SOV_CODE_LENGTH = 100
export const MIN_COMPANY_NICKNAME_LENGTH = 3
export const MAX_EMAIL_MESSAGE_CHARACTERS = 4000
export const MAX_EMAIL_SUBJECT_CHARACTERS = 512
export const MAX_BOND_NUMBER_CHARACTERS = 100

// Collections
export const MAX_COLLECTIONS_REMINDER_INTERVAL_AMOUNT = 100

export const MAX_FILENAME_LENGTH = 100

// Email verification constants for company email aliases
export const EMAIL_VERIFICATION_CODE_LENGTH = 6
export const EMAIL_VERIFICATION_EXPIRATION_HOURS = 2

// Regex for 2-3 letter state code on address forms
export const STATE_CODE_VALIDATION_REGEX = /^[A-Z]{2,3}$/

// Character limits found by typing into corresponding Quickbooks inputs until it maxes out
export const QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT = 41
export const QUICKBOOKS_JOB_MAX_CHAR_COUNT = 41
export const QUICKBOOKS_ITEM_MAX_CHAR_COUNT = 31
export const QUICKBOOKS_DESCRIPTION_MAX_CHAR_COUNT = 4095
export const QUICKBOOKS_INVOICE_NUMBER_MAX_CHAR_COUNT = 20

// ComputerEase file import string lengths are based on the schema in batchinvoices.xsd
export const COMPUTER_EASE_CUSTOMER_ID_MAX_LENGTH = 8
export const COMPUTER_EASE_JOB_ID_MAX_LENGTH = 10
export const COMPUTER_EASE_ACCOUNT_NUMBER_MAX_LENGTH = 9

// Foundation enforces a char limit
export const FOUNDATION_CUSTOMER_NUMBER_MAX_LENGTH = 10

export function isValidEmail(email: string): boolean {
  const isValid = validateEmail(email)
  const domain = _.last(email.split('.'))?.toLowerCase()
  if (!domain) {
    return false
  }

  const isValidDomain = Array.from(tlds).includes(domain)
  return isValid && isValidDomain
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  const regex = /^\+[1-9]\d{1,14}(x\d{1,4})?$/
  return regex.test(phoneNumber)
}

/**
 * Attempts to clean and validate an email address.
 * If the email is valid, returns it as is.
 * If the email is invalid, attempts to clean it by:
 * - Removing special characters except @, ., -, and +
 * - Removing whitespace
 * - Converting to lowercase
 * - Removing multiple consecutive @ or . characters
 *
 * Returns null if the email cannot be cleaned to a valid format.
 */
export function cleanAndValidateEmail(email: string): string | null {
  if (!email) {
    return null
  }

  // Clean the email
  const cleaned = email
    .toLowerCase()
    // Remove all special characters except @, ., -, and +
    .replace(/[^a-z0-9@.+-]/g, '')
    // Remove whitespace
    .replace(/\s+/g, '')
    // Remove multiple consecutive @ characters and keep only the last one
    .replace(/.*@/, (match) => match.replace(/@/g, '').concat('@'))
    // Remove multiple consecutive dots
    .replace(/\.+/g, '.')
    // Remove leading/trailing @, ., -, and +
    .replace(/^[@.+-]+|[@.+-]+$/g, '')

  // Basic email format check
  const parts = cleaned.split('@')
  if (parts.length !== 2 || !parts[0] || !parts[1]) {
    return null
  }

  // Check if the cleaned version is valid
  if (isValidEmail(cleaned)) {
    return cleaned
  }

  return null
}
