import { Button, Grid2, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { LienWaiverTemplateSetTable, TemplateVariantWithId } from './LienWaiverTemplateSetTable'

export type LienWaiverTemplateSetValues = {
  conditionalFinalVariant: TemplateVariantWithId | null
  conditionalProgressVariant: TemplateVariantWithId | null
  unconditionalFinalVariant: TemplateVariantWithId | null
  unconditionalProgressVariant: TemplateVariantWithId | null
}

type LienWaiverTemplateSetProps = {
  title: string
  // `null` here means that no settings have been created yet (contract creation)
  lienWaiverSet: LienWaiverTemplateSetValues | null
  onChange: (lienWaiverSet: LienWaiverTemplateSetValues | null) => Promise<boolean>
  onReset?: () => void
}

export function LienWaiverTemplateSet({
  title,
  lienWaiverSet,
  onChange,
  onReset,
}: LienWaiverTemplateSetProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [conditionalFinal, setConditionalFinal] = useState<TemplateVariantWithId | null>(null)
  const [conditionalProgress, setConditionalProgress] = useState<TemplateVariantWithId | null>(null)
  const [unconditionalFinal, setUnconditionalFinal] = useState<TemplateVariantWithId | null>(null)
  const [unconditionalProgress, setUnconditionalProgress] = useState<TemplateVariantWithId | null>(
    null
  )

  useEffect(() => {
    setConditionalFinal(lienWaiverSet?.conditionalFinalVariant ?? null)
  }, [lienWaiverSet?.conditionalFinalVariant])
  useEffect(() => {
    setConditionalProgress(lienWaiverSet?.conditionalProgressVariant ?? null)
  }, [lienWaiverSet?.conditionalProgressVariant])
  useEffect(() => {
    setUnconditionalFinal(lienWaiverSet?.unconditionalFinalVariant ?? null)
  }, [lienWaiverSet?.unconditionalFinalVariant])
  useEffect(() => {
    setUnconditionalProgress(lienWaiverSet?.unconditionalProgressVariant ?? null)
  }, [lienWaiverSet?.unconditionalProgressVariant])

  const onEdit = useCallback(() => setIsEditing(true), [])

  const onCancel = useCallback(() => {
    if (onReset) {
      onReset()
    }
    setIsEditing(false)
  }, [onReset])

  const onSave = useCallback(async () => {
    const confirmed = window.confirm('Are you sure you want to save those template settings?')
    if (!confirmed) {
      return
    }
    const finalValues = {
      conditionalFinalVariant: conditionalFinal,
      conditionalProgressVariant: conditionalProgress,
      unconditionalFinalVariant: unconditionalFinal,
      unconditionalProgressVariant: unconditionalProgress,
    }
    const hasDefinedTemplates = Object.values(finalValues).some(Boolean)
    const success = await onChange(hasDefinedTemplates ? finalValues : null)
    if (success) {
      setIsEditing(false)
    }
  }, [conditionalFinal, conditionalProgress, onChange, unconditionalFinal, unconditionalProgress])

  return (
    <>
      <Grid2 container justifyContent="space-between">
        <Grid2>
          <Typography variant="h6">{title}</Typography>
        </Grid2>
        <Grid2>
          {!isEditing && (
            <>
              <Button onClick={onEdit} color="secondary">
                Edit
              </Button>
            </>
          )}
          {isEditing && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={onSave} color="secondary">
                Save
              </Button>
            </>
          )}
        </Grid2>
      </Grid2>
      <LienWaiverTemplateSetTable
        isEditing={isEditing}
        conditionalFinalVariant={conditionalFinal}
        onUpdateConditionalFinal={setConditionalFinal}
        conditionalProgressVariant={conditionalProgress}
        onUpdateConditionalProgress={setConditionalProgress}
        unconditionalFinalVariant={unconditionalFinal}
        onUpdateUnconditionalFinal={setUnconditionalFinal}
        unconditionalProgressVariant={unconditionalProgress}
        onUpdateUnconditionalProgress={setUnconditionalProgress}
      />
    </>
  )
}
