import type { Theme } from '@mui/material/styles'
// eslint-disable-next-line no-restricted-imports
import { ClassNameMap, Styles, makeStyles } from '@mui/styles'

type StyleFunc<ClassKey extends string> = (theme: Theme) => Record<ClassKey, unknown>

/**
 * A version of makeStyles that does not typecheck the style object that is passed in. This allows
 * type-checking and linting to be much faster across the entire codebase, although autocompletion
 * won't be available on styles.
 */
export function makeStylesFast<Props extends object, ClassKey extends string>(
  func: StyleFunc<ClassKey>
): keyof Props extends never
  ? // `makeStyles` where the passed `styles` do not depend on props
    () => ClassNameMap<ClassKey>
  : // `makeStyles` where the passed `styles` do depend on props
    (props: Props) => ClassNameMap<ClassKey> {
  return makeStyles(func as Styles<unknown, Props, ClassKey>)
}
