import { gql } from '@apollo/client'
import { Autocomplete, Button, styled, TextField } from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  BillingType,
  ButtonLabelSpinner,
  Column,
  fuseSearch,
  Row,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { StateDropdown } from '../../common/components/StateDropdown'
import {
  CompanyPayAppFormTemplateSetProperties,
  useCreatePayAppFormTemplateSetMutation,
  useDeletePayAppFormTemplateSetMutation,
  useTemplateVariantForAutocompleteLazyQuery,
  useUpdatePayAppFormTemplateSetMutation,
} from '../../common/graphql/apollo-operations'
import * as fragments from '../../common/graphql/Fragments'
import {
  PayAppRequirementGroupWithKey,
  validatePayAppRequirementGroups,
} from '../../common/util/PayAppRequirements'
import PayAppRequirementGroups from '../pay-app-requirement/PayAppRequirementGroups'
import { FormSetsCard } from './FormSetCard'

const StyledPayAppFormSet = styled(Column)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: 0,
  '& .editingTopLevel': {
    paddingTop: theme.spacing(2),
  },
  '& .deleteButton': {
    alignSelf: 'flex-end',
  },
}))

gql`
  mutation createPayAppFormTemplateSet($input: CreatePayAppFormTemplateSetInput!) {
    createPayAppFormTemplateSet(input: $input) {
      id
      payAppFormTemplateSets {
        ...CompanyPayAppFormTemplateSetProperties
      }
    }
  }
  ${fragments.companyPayAppFormTemplateSet}
`

gql`
  mutation updatePayAppFormTemplateSet($input: UpdatePayAppFormTemplateSetInput!) {
    updatePayAppFormTemplateSet(input: $input) {
      id
      payAppFormTemplateSets {
        ...CompanyPayAppFormTemplateSetProperties
      }
    }
  }
  ${fragments.companyPayAppFormTemplateSet}
`

gql`
  mutation deletePayAppFormTemplateSet($input: DeletePayAppFormTemplateSetInput!) {
    deletePayAppFormTemplateSet(input: $input) {
      id
      payAppFormTemplateSets {
        ...CompanyPayAppFormTemplateSetProperties
      }
    }
  }
  ${fragments.companyPayAppFormTemplateSet}
`

type GeneralContractorOption = {
  id: string
  name: string
}

const EMPTY_GC_OPTION = {
  id: '',
  name: '',
}

interface PayAppFormSetProps {
  payAppFormSet: CompanyPayAppFormTemplateSetProperties | null
  billingType: BillingType
  usesManualStoredMaterials: boolean
  onCancelCreate: () => void
  generalContractors: GeneralContractorOption[]
  scrollToId: string | null
  companyId: string
}

export function PayAppFormSet({
  payAppFormSet,
  billingType,
  usesManualStoredMaterials,
  onCancelCreate,
  generalContractors,
  scrollToId,
  companyId,
}: PayAppFormSetProps) {
  const snackbar = useSitelineSnackbar()

  const isNewFormSet = payAppFormSet === null
  const initialFormName = payAppFormSet?.name ?? ''
  const initialGc = payAppFormSet?.generalContractor ?? EMPTY_GC_OPTION
  const initialState = payAppFormSet?.state ?? null
  const initialIsOpen = !!scrollToId && scrollToId === payAppFormSet?.id

  const initialGroups = useMemo(() => {
    if (!payAppFormSet) {
      return []
    }
    const payAppRequirementGroups = [...payAppFormSet.payAppRequirementGroups]
    return _.chain(payAppRequirementGroups)
      .orderBy((a) => a.order, 'asc')
      .map(
        (requirementGroup): PayAppRequirementGroupWithKey => ({
          id: requirementGroup.id,
          key: requirementGroup.id,
          order: requirementGroup.order,
          requirements: requirementGroup.payAppRequirements.map((requirement) => ({
            id: requirement.id,
            key: requirement.id,
            groupOrder: requirement.groupOrder,
            templateVariantId: requirement.templateVariant?.id ?? null,
            conditions: requirement.conditions,
          })),
        })
      )
      .value()
  }, [payAppFormSet])

  const [isEditing, handleEdit, handleCancelEdit] = useToggle()
  const [formName, setFormName] = useState<string>(initialFormName)
  const [gc, setGc] = useState<GeneralContractorOption>(initialGc)
  const [state, setState] = useState<string | null>(initialState)
  const [gcSearchQuery, setGcSearchQuery] = useState<string>('')
  const [isGcAutocompleteOpen, handleOpenGcAutocomplete, handleCloseGcAutocomplete] = useToggle()
  const [requirementGroups, setRequirementGroups] =
    useState<PayAppRequirementGroupWithKey[]>(initialGroups)

  const [getVariant] = useTemplateVariantForAutocompleteLazyQuery()

  const [createTemplateSet, { loading: creating }] = useCreatePayAppFormTemplateSetMutation()
  const [updateTemplateSet, { loading: updating }] = useUpdatePayAppFormTemplateSetMutation()
  const [deleteTemplateSet, { loading: deleting }] = useDeletePayAppFormTemplateSetMutation()

  const filteredGeneralContractors = useMemo(() => {
    return fuseSearch(generalContractors, gcSearchQuery, ['name'])
  }, [generalContractors, gcSearchQuery])

  const getFormTemplateVariant = useCallback(
    async (id: string) => {
      const template = await getVariant({
        variables: { id },
      })
      return template.data?.formTemplateVariant
    },
    [getVariant]
  )

  const handleReset = useCallback(() => {
    setFormName(initialFormName)
    setGc(initialGc)
    setState(initialState)
    setRequirementGroups(_.cloneDeep(initialGroups))
    setGcSearchQuery('')
    handleCloseGcAutocomplete()
  }, [handleCloseGcAutocomplete, initialFormName, initialGc, initialGroups, initialState])

  const handleCancel = useCallback(() => {
    handleReset()
    handleCancelEdit()
    onCancelCreate()
  }, [handleReset, handleCancelEdit, onCancelCreate])

  const handleDelete = useCallback(async () => {
    if (payAppFormSet === null) {
      handleCancel()
      return
    }
    const confirmed = window.confirm(`Are you sure you want to delete ${payAppFormSet.name}`)
    if (!confirmed) {
      return
    }
    try {
      await deleteTemplateSet({
        variables: {
          input: {
            templateSetId: payAppFormSet.id,
          },
        },
      })
    } catch (error) {
      snackbar.showError(error.message)
      return
    }
  }, [deleteTemplateSet, handleCancel, payAppFormSet, snackbar])

  const handleSave = useCallback(async () => {
    try {
      // Check that all the pay app requirement groups are valid
      await validatePayAppRequirementGroups(requirementGroups, getFormTemplateVariant)
      const requirementGroupsInput = requirementGroups.map((group) => ({
        id: group.id,
        order: group.order,
        requirements: group.requirements.map((requirement) => ({
          id: requirement.id,
          templateVariantId: requirement.templateVariantId,
          groupOrder: requirement.groupOrder,
          conditions: requirement.conditions,
        })),
      }))
      const input = {
        name: formName,
        billingType,
        generalContractorCompanyId: gc.id || null,
        state,
        requirementGroups: requirementGroupsInput,
      }

      if (isNewFormSet) {
        await createTemplateSet({
          variables: {
            input: {
              ...input,
              companyId,
            },
          },
        })
      } else {
        await updateTemplateSet({
          variables: {
            input: {
              ...input,
              templateSetId: payAppFormSet.id,
            },
          },
        })
      }
    } catch (error) {
      snackbar.showError(error.message)
      return
    }
  }, [
    billingType,
    companyId,
    createTemplateSet,
    formName,
    gc.id,
    getFormTemplateVariant,
    isNewFormSet,
    payAppFormSet?.id,
    requirementGroups,
    snackbar,
    state,
    updateTemplateSet,
  ])

  useEffect(() => {
    handleReset()
  }, [handleReset])

  const isEditingOrCreating = isEditing || isNewFormSet
  const isLoading = creating || updating || deleting

  return (
    <FormSetsCard
      name={initialFormName}
      isNewFormSet={isNewFormSet}
      isEditing={isEditing}
      onEdit={handleEdit}
      onCancelEdit={handleCancel}
      onSave={handleSave}
      initialIsOpen={initialIsOpen}
      saving={creating || updating}
      disableSave={!formName || isLoading}
      disableCancel={isLoading}
      gc={gc.name}
      state={state ?? ''}
    >
      <StyledPayAppFormSet id={payAppFormSet?.id}>
        {isEditingOrCreating && (
          <Row gap={8}>
            <TextField
              label="Form set name*"
              type="text"
              size="small"
              value={formName}
              onChange={(ev) => setFormName(ev.target.value)}
              style={{ width: 300 }}
            />
            <Autocomplete
              options={filteredGeneralContractors}
              open={isGcAutocompleteOpen}
              onOpen={handleOpenGcAutocomplete}
              onClose={handleCloseGcAutocomplete}
              inputValue={gcSearchQuery}
              onInputChange={(_, value) => setGcSearchQuery(value)}
              getOptionLabel={(option) => option.name}
              value={gc}
              size="small"
              style={{ width: 300 }}
              onChange={(_, value) => setGc(value ?? EMPTY_GC_OPTION)}
              renderInput={(params) => <TextField {...params} label="General contractor" />}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
            />
            <StateDropdown state={state} onStateChange={setState} />
          </Row>
        )}
        <PayAppRequirementGroups
          title=""
          billingType={billingType}
          usesManualStoredMaterials={usesManualStoredMaterials}
          payAppRequirementGroups={requirementGroups}
          setPayAppRequirementGroups={setRequirementGroups}
          isEditing={isEditingOrCreating}
          elevation={0}
        />
        {isEditing && (
          <Button
            variant="text"
            color="error"
            disabled={isLoading}
            startIcon={deleting ? <ButtonLabelSpinner /> : undefined}
            onClick={handleDelete}
            className="deleteButton"
          >
            Delete
          </Button>
        )}
      </StyledPayAppFormSet>
    </FormSetsCard>
  )
}
