import AddIcon from '@mui/icons-material/Add'
import { Button, Collapse } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Column, Row, SitelineText, SitelineTooltip } from 'siteline-common-web'

export enum FormSetType {
  PAY_APP_LUMP_SUM = 'PAY_APP_LUMP_SUM',
  PAY_APP_UNIT_PRICE = 'PAY_APP_UNIT_PRICE',
  PAY_APP_TIME_AND_MATERIALS = 'PAY_APP_TIME_AND_MATERIALS',
  PRIMARY_LIEN_WAIVER = 'PRIMARY_LIEN_WAIVER',
  VENDOR_LIEN_WAIVER = 'VENDOR_LIEN_WAIVER',
}

interface CompanyFormSetsSectionProps {
  formSetType: FormSetType
  name: string
  isEmptyState: boolean
  creatingFormSet: FormSetType | null
  onCreateFormSet: (formSetType: FormSetType) => void
  /** Will collapse section if false. Should be false if searching & no results are found. */
  shouldDisplaySection: boolean
}

export function CompanyFormSetsSection({
  name,
  isEmptyState,
  creatingFormSet,
  onCreateFormSet,
  children,
  formSetType,
  shouldDisplaySection,
}: PropsWithChildren<CompanyFormSetsSectionProps>) {
  const isCreatingFormSet = creatingFormSet !== null
  const isCreatingFormSetOfType = creatingFormSet === formSetType

  return (
    <Collapse in={shouldDisplaySection}>
      <Column gap={16}>
        <Row alignItems="center" justifyContent="space-between">
          <SitelineText variant="h5">{name}</SitelineText>
          {!isEmptyState && (
            <SitelineTooltip
              title={
                isCreatingFormSet
                  ? "You are already creating a form set. Cancel or save the form set you're editing before creating a new one."
                  : null
              }
            >
              <div>
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  disabled={isCreatingFormSet}
                  onClick={() => onCreateFormSet(formSetType)}
                >
                  CREATE FORM SET
                </Button>
              </div>
            </SitelineTooltip>
          )}
        </Row>
        {children}
        {isEmptyState && !isCreatingFormSetOfType && (
          <SitelineTooltip
            title={
              isCreatingFormSet
                ? "You are already creating a form set. Cancel or save the form set you're editing before creating a new one."
                : null
            }
          >
            <div>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                disabled={isCreatingFormSet}
                onClick={() => onCreateFormSet(formSetType)}
                style={{ alignSelf: 'flex-start' }}
              >
                CREATE FORM SET
              </Button>
            </div>
          </SitelineTooltip>
        )}
      </Column>
    </Collapse>
  )
}
