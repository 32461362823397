import { gql } from '@apollo/client'
import ApprovalIcon from '@mui/icons-material/Approval'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import GestureIcon from '@mui/icons-material/Gesture'
import ImageIcon from '@mui/icons-material/Image'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { ChangeEvent, useMemo } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import {
  EditableCardRowAutocomplete,
  EditableCardRowCheckbox,
  EditableCardRowNumber,
  EditableCardRowSelect,
  EditableCardRowText,
} from '../../common/components/EditableCardRow'
import * as fragments from '../../common/graphql/Fragments'
import { CompanyExternalApiTokenDialog } from '../../components/company-details/CompanyExternalApiTokenDialog'
import {
  CompanyProperties,
  CompanyType,
  FormTemplateType,
  StoredFileInput,
  StoredFileType,
  useDeleteCompanyMutation,
  useSitelineTeamMembersQuery,
  useUpdateCompanyMutation,
} from '../graphql/apollo-operations'
import { EditableFormTemplateCardRow } from './EditableFormTemplateCardRow'
import IdentifierRow from './IdentifierRow'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  picture: {
    height: 200,
    backgroundSize: 'contain',
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  notary: {
    '& img': {
      maxWidth: 200,
    },
    '& .MuiTableCell-root:last-child': {
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
  },
}))

gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...CompanyProperties
    }
  }
  ${fragments.company}
`

gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      id
    }
  }
`

interface CompanyCardProps {
  company: CompanyProperties
  title?: string
  withLink?: boolean
  withActions?: boolean
  readOnly?: boolean
}

type ImageType = 'logo' | 'notary stamp' | 'notary signature' | 'secondary signature'

export default function CompanyCard({
  company,
  title,
  withLink = true,
  withActions = false,
  readOnly = true,
}: CompanyCardProps) {
  const classes = useStyles()
  const [isApiTokenDialogOpen, openApiTokenDialog, closeApiTokenDialog] = useToggle()
  const [updateCompanyMutation] = useUpdateCompanyMutation()
  const [deleteCompanyMutation] = useDeleteCompanyMutation()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()
  const { data: usersData } = useSitelineTeamMembersQuery()
  const admins = useMemo(() => {
    const users = usersData?.sitelineTeamMembers ?? []
    return _.orderBy(users, (user) => user.email, 'asc')
  }, [usersData?.sitelineTeamMembers])

  const onUpdateImage = (ev: ChangeEvent<HTMLInputElement> | null, type: ImageType) => {
    const files = ev?.target.files
    const file = _.first(files)
    if (ev) {
      ev.target.value = ''
    }

    const confirmed = window.confirm(`Are you sure you want to update this company ${type}?`)
    if (!confirmed) {
      return
    }

    const fileTypes: Partial<Record<string, StoredFileType>> = {
      'image/jpeg': StoredFileType.JPEG,
      'image/png': StoredFileType.PNG,
    }

    if (file) {
      const attachmentType = fileTypes[file.type]
      if (!attachmentType) {
        snackbar.showError('Company logo must be a JPEG or PNG file.')
        return
      }
    }

    const image: StoredFileInput | null = file
      ? {
          file,
          name: file.name,
        }
      : null

    snackbar.showLoading(`Updating company ${type}...`)
    updateCompanyMutation({
      variables: {
        input: {
          id: company.id,
          ...(type === 'logo' && { picture: image }),
          ...(type === 'notary stamp' && { notaryStamp: image }),
          ...(type === 'notary signature' && { notarySignature: image }),
          ...(type === 'secondary signature' && { secondarySignature: image }),
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const onDelete = () => {
    if (!window.confirm('Are you sure you want to delete this company?')) {
      return
    }
    snackbar.showLoading()
    deleteCompanyMutation({
      variables: {
        id: company.id,
      },
    })
      .then(() => {
        snackbar.showSuccess()
        navigate('/')
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const companyTypes = [
    CompanyType.LENDER,
    CompanyType.OWNER_DEVELOPER,
    CompanyType.GENERAL_CONTRACTOR,
    CompanyType.SUBCONTRACTOR,
    CompanyType.ARCHITECT,
  ]

  return (
    <Card className={classes.card}>
      <CardHeader
        title={title ?? 'Company'}
        action={
          <>
            {withLink && (
              <Button
                startIcon={<OpenInNewIcon />}
                color="primary"
                component={RouterLink}
                size="small"
                to={`/companies/${company.id}`}
              >
                View
              </Button>
            )}
          </>
        }
      />
      <Divider />
      {company.picture && <CardMedia image={company.picture.url} className={classes.picture} />}
      <CardContent className={classes.cardContent}>
        <Table>
          <TableBody>
            <IdentifierRow id={company.id} />
            {/* Name */}
            <EditableCardRowText
              readOnly={readOnly}
              value={company.name}
              label="Name"
              variables={(value) => ({
                input: {
                  id: company.id,
                  name: value,
                },
              })}
              mutate={updateCompanyMutation}
            />

            {/* Nickname */}
            <EditableCardRowText
              readOnly={readOnly}
              value={company.nickname ?? ''}
              label="Nickname"
              variables={(value) => ({
                input: {
                  id: company.id,
                  nickname: value,
                },
              })}
              mutate={updateCompanyMutation}
            />

            {/* Phone number */}
            <EditableCardRowText
              readOnly={readOnly}
              value={company.phoneNumber ?? ''}
              label="Phone number"
              variables={(value) => ({
                input: {
                  id: company.id,
                  phoneNumber: value,
                },
              })}
              mutate={updateCompanyMutation}
            />

            {/* Expected number of projects */}
            <EditableCardRowText
              readOnly={readOnly}
              value={String(company.expectedNumberOfProjects)}
              label={
                <SitelineText
                  variant="body2"
                  endIcon={
                    <Tooltip
                      title="How many projects do we expect this company to bill? This is used to assess the status of a company's onboarding."
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon fontSize="small" sx={{ color: colors.grey50 }} />
                    </Tooltip>
                  }
                >
                  Number of projects expected
                </SitelineText>
              }
              variables={(value) => ({
                input: {
                  id: company.id,
                  expectedNumberOfProjects: Number(value),
                },
              })}
              mutate={updateCompanyMutation}
            />

            {/* Type */}
            <EditableCardRowSelect
              readOnly={readOnly}
              value={company.type}
              label="Type"
              options={companyTypes.map((type) => ({ key: type, value: type }))}
              variables={(value: CompanyType) => ({
                input: {
                  id: company.id,
                  type: value,
                },
              })}
              mutate={updateCompanyMutation}
            />

            {/* Subtrade */}
            <EditableCardRowText
              readOnly={readOnly}
              value={company.metadata.subtrade ?? ''}
              label="Subtrade"
              mutate={updateCompanyMutation}
              variables={(value) => ({
                input: {
                  id: company.id,
                  metadata: {
                    subtrade: value === '' ? null : value,
                    domains: company.metadata.domains,
                    domainSignup: company.metadata.domainSignup,
                    monthlyCreatePayAppReminderDate:
                      company.metadata.monthlyCreatePayAppReminderDate,
                  },
                },
              })}
            />

            {/* Monthly create pay app reminder date */}
            <EditableCardRowNumber
              readOnly={readOnly}
              value={company.metadata.monthlyCreatePayAppReminderDate ?? 0}
              label={
                <>
                  Monthly Create Pay App Reminder Date (1-28).
                  <br />
                  If 0, defaults to 2nd Monday
                </>
              }
              mutate={updateCompanyMutation}
              variables={(value) => ({
                input: {
                  id: company.id,
                  metadata: {
                    subtrade: company.metadata.subtrade,
                    domains: company.metadata.domains,
                    domainSignup: company.metadata.domainSignup,
                    monthlyCreatePayAppReminderDate: value === 0 ? null : value,
                  },
                },
              })}
              minimum={1}
              maximum={28}
            />

            {/* Default Quick Billing Template */}
            <EditableFormTemplateCardRow
              readOnly={readOnly}
              defaultTemplateId={company.defaultQuickBillingTemplate?.id}
              variables={(value?: string | null) => ({
                input: {
                  id: company.id,
                  defaultQuickBillingTemplateId: value,
                },
              })}
              label="Default Quick Billing Template"
              templateType={FormTemplateType.PAY_APP_QUICK}
              mutate={updateCompanyMutation}
            />

            {/* Default setting for including audit trail on new projects */}
            <EditableCardRowCheckbox
              readOnly={readOnly}
              label="Enable audit trail for new projects"
              value={company.defaultShowAuditItemOnSignedPackages}
              mutate={updateCompanyMutation}
              variables={(value: boolean) => ({
                input: {
                  id: company.id,
                  defaultShowAuditItemOnSignedPackages: value,
                },
              })}
            />

            {/* Company setting for allowing overbilling on lump sum projects */}
            <EditableCardRowCheckbox
              readOnly={readOnly}
              label="Allow over-billing on lump sum projects"
              value={company.allowLumpSumOverbilling}
              mutate={updateCompanyMutation}
              variables={(value: boolean) => ({
                input: {
                  id: company.id,
                  allowLumpSumOverbilling: value,
                },
              })}
            />

            {/* Siteline CSM responsible for this company */}
            <EditableCardRowAutocomplete
              readOnly={readOnly}
              formatValue={(key) => admins.find((user) => user.id === key)?.email ?? ''}
              value={company.sitelinePointOfContact?.id ?? ''}
              label="Account owner"
              options={admins.map((user) => ({
                key: user.id,
                value: user.email,
              }))}
              variables={(value: string) => ({
                input: {
                  id: company.id,
                  sitelinePointOfContactId: value,
                },
              })}
              mutate={updateCompanyMutation}
            />

            {/* Demo company */}
            <EditableCardRowCheckbox
              readOnly={readOnly}
              label="Demo company"
              value={company.isDemoCompany}
              mutate={updateCompanyMutation}
              variables={(value: boolean) => ({
                input: {
                  id: company.id,
                  isDemoCompany: value,
                },
              })}
            />

            {/* Notary stamp  */}
            {company.notaryStamp && (
              <TableRow className={classes.notary}>
                <TableCell>Notary Stamp</TableCell>
                <TableCell>
                  <img alt="Notary Stamp" src={company.notaryStamp.url} />
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => onUpdateImage(null, 'notary stamp')}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}

            {/* Notary signature  */}
            {company.notarySignature && (
              <TableRow className={classes.notary}>
                <TableCell>Notary Signature</TableCell>
                <TableCell>
                  <img alt="Notary Signature" src={company.notarySignature.url} />
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => onUpdateImage(null, 'notary signature')}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}

            {/* Secondary signature  */}
            {company.secondarySignature && (
              <TableRow className={classes.notary}>
                <TableCell>Secondary Signature</TableCell>
                <TableCell>
                  <img alt="Secondary Signature" src={company.secondarySignature.url} />
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => onUpdateImage(null, 'secondary signature')}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
      {withActions && (
        <CardActions className={classes.actions} disableSpacing>
          <Button startIcon={<ImageIcon />} color="primary" size="small" component="label">
            Update logo
            <input type="file" hidden onChange={(event) => onUpdateImage(event, 'logo')} />
          </Button>
          <Button startIcon={<ApprovalIcon />} color="primary" size="small" component="label">
            Update notary stamp
            <input type="file" hidden onChange={(event) => onUpdateImage(event, 'notary stamp')} />
          </Button>
          <Button startIcon={<GestureIcon />} color="primary" size="small" component="label">
            Update notary signature
            <input
              type="file"
              hidden
              onChange={(event) => onUpdateImage(event, 'notary signature')}
            />
          </Button>
          <Button startIcon={<GestureIcon />} color="primary" size="small" component="label">
            Update secondary signature
            <input
              type="file"
              hidden
              onChange={(event) => onUpdateImage(event, 'secondary signature')}
            />
          </Button>
          <Button
            startIcon={<VpnKeyIcon />}
            color="primary"
            size="small"
            component="label"
            onClick={openApiTokenDialog}
          >
            Rotate external API token
          </Button>
          <Button
            startIcon={<DeleteOutlineIcon />}
            color="secondary"
            size="small"
            onClick={() => onDelete()}
          >
            Delete company
          </Button>
        </CardActions>
      )}
      <CompanyExternalApiTokenDialog
        open={isApiTokenDialogOpen}
        onClose={closeApiTokenDialog}
        companyId={company.id}
      />
    </Card>
  )
}
