import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ErrorIcon from '@mui/icons-material/Error'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { IconButton, TableCell, TableRow } from '@mui/material'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import ReactJson, { OnCopyProps } from 'react-json-view'
import { colors, useSitelineSnackbar } from 'siteline-common-web'
import { openLogs, TaskLog, timestampToMoment } from './JobsPage'

type TaskLogRowProps = {
  taskLog: TaskLog
}

/**
 * A single log line from a task execution.
 */
export function TaskLogRow({ taskLog }: TaskLogRowProps) {
  const [expanded, setExpanded] = useState(false)
  const snackbar = useSitelineSnackbar()

  const message = useMemo(() => {
    if (
      _.isObject(taskLog.log.message) &&
      'message' in taskLog.log.message &&
      _.isString(taskLog.log.message.message)
    ) {
      return taskLog.log.message.message
    }
    if (taskLog.log.httpRequest) {
      return `${taskLog.log.httpRequest.requestMethod} ${taskLog.log.httpRequest.requestUrl}`
    }
    return JSON.stringify(taskLog.log.message)
  }, [taskLog.log.httpRequest, taskLog.log.message])

  const handleCopy = useCallback(
    (clip: OnCopyProps) => {
      let stringToCopy: string
      if (_.isString(clip.src)) {
        stringToCopy = clip.src
      } else {
        stringToCopy = JSON.stringify(clip.src)
      }
      copy(stringToCopy)
      snackbar.showSuccess(`Copied to clipboard`)
    },
    [snackbar]
  )

  const handleOpenLog = useCallback(() => {
    const filter = `resource.type="cloud_run_revision"`
    openLogs({ filter, pinnedLog: taskLog.log })
  }, [taskLog.log])

  const cleanBreadcrumbs = useMemo(() => {
    if (!_.isObject(taskLog.log.message)) {
      return null
    }
    const withoutMessage = _.omit(taskLog.log.message, 'message')
    if (_.isEmpty(withoutMessage)) {
      return null
    }
    return withoutMessage
  }, [taskLog.log.message])

  return (
    <>
      <TableRow hover key={taskLog.log['logging.googleapis.com/insertId']}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setExpanded(!expanded)}
            sx={{ marginTop: -3, marginBottom: -2 }}
          >
            {expanded ? <ExpandMoreIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </IconButton>
          {taskLog.log.severity === 'INFO' ? (
            <InfoIcon htmlColor={colors.blue20} fontSize="small" />
          ) : (
            <ErrorIcon htmlColor={colors.red50} fontSize="small" />
          )}
        </TableCell>
        <TableCell>
          {timestampToMoment(taskLog.log.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS')}
        </TableCell>
        <TableCell>{message}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <IconButton size="small" onClick={handleOpenLog}>
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {cleanBreadcrumbs && (
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell colSpan={2}>
            <ReactJson
              name={null}
              src={cleanBreadcrumbs}
              displayDataTypes={false}
              displayObjectSize={false}
              quotesOnKeys={false}
              enableClipboard={handleCopy}
            />
          </TableCell>
        </TableRow>
      )}
      {expanded && (
        <TableRow>
          <TableCell colSpan={4}>
            <ReactJson
              name={null}
              shouldCollapse={(field) => {
                if (field.name === null) {
                  return false
                }
                if (field.name === 'message') {
                  return false
                }
                if (field.namespace.includes('message')) {
                  return false
                }
                return true
              }}
              src={taskLog.log}
              displayDataTypes={false}
              displayObjectSize={false}
              quotesOnKeys={false}
              enableClipboard={handleCopy}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
