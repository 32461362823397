import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  FormControl,
  Grid2,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { styled } from '@mui/system'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import {
  BillingType,
  Column,
  fuseSearch,
  Row,
  SitelineText,
  Spacer,
  useToggle,
} from 'siteline-common-web'
import { StateDropdown } from '../../common/components/StateDropdown'
import { useGetGeneralContractorsQuery } from '../../common/graphql/apollo-operations'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { CompanyDetailsProjectFormCollection } from './CompanyDetailsProjectFormCollection'

const StyledCompanyDetailsProjectFormCollections = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1),
  '& .header': {
    paddingBottom: theme.spacing(1),
  },
}))

interface CompanyDetailsProjectFormCollectionsProps {
  company: DetailedCompany
}

export function CompanyDetailsProjectFormCollections({
  company,
}: CompanyDetailsProjectFormCollectionsProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const [billingTypeFilter, setBillingTypeFilter] = useState<BillingType | null>(null)
  const [stateFilter, setStateFilter] = useState<string | null>(null)
  const [creatingCollection, handleCreateCollection, handleCancelCollection] = useToggle()

  const { data: generalContractorsData } = useGetGeneralContractorsQuery({
    variables: { companyId: company.id },
  })

  const allGeneralContractors = useMemo(() => {
    if (generalContractorsData === undefined) {
      return []
    }
    return _.orderBy([...generalContractorsData.generalContractors], (gc) => gc.name, ['asc'])
  }, [generalContractorsData])

  const filteredCollections = useMemo(() => {
    return company.formTemplateCollections.filter((collection) => {
      let matches = true
      if (billingTypeFilter !== null) {
        matches = collection.billingType === billingTypeFilter
      }
      if (stateFilter !== null) {
        matches = matches && collection.state === stateFilter
      }
      return matches
    })
  }, [company.formTemplateCollections, billingTypeFilter, stateFilter])

  const searchedCollections = useMemo(() => {
    return fuseSearch(filteredCollections, searchQuery, ['name', 'generalContractor.name'], {
      ignoreLocation: true,
    })
  }, [filteredCollections, searchQuery])

  const handleUnfocus = useCallback(() => {
    // Force the select field to lose focus
    // so the label isn’t stuck floating.
    // Use setTimeout to ensure the blur event fires after the event loop.
    setTimeout(() => {
      const element = document.activeElement as HTMLElement | undefined
      element?.blur()
    })
  }, [])

  return (
    <StyledCompanyDetailsProjectFormCollections>
      <Column gap={8} className="header">
        <Row gap={24} alignItems="center">
          <SitelineText variant="h4">Project form kits</SitelineText>
          {company.formTemplateCollections.length > 0 && (
            <TextField
              value={searchQuery}
              onChange={(ev) => setSearchQuery(ev.target.value)}
              style={{ width: 400, backgroundColor: 'white' }}
              size="small"
              variant="outlined"
              placeholder="Search by project form kit or GC name"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          <FormControl size="small" sx={{ width: 200 }}>
            <InputLabel>Billing Type</InputLabel>
            <Select
              label="Billing Type"
              value={billingTypeFilter || null}
              onChange={(event) => {
                if (event.target.value === '') {
                  setBillingTypeFilter(null)
                  handleUnfocus()
                  return
                }
                setBillingTypeFilter(event.target.value as BillingType)
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value={BillingType.LUMP_SUM}>Lump Sum</MenuItem>
              <MenuItem value={BillingType.UNIT_PRICE}>Unit Price</MenuItem>
              <MenuItem value={BillingType.TIME_AND_MATERIALS}>Time &amp; Materials</MenuItem>
            </Select>
          </FormControl>
          <StateDropdown state={stateFilter} onStateChange={setStateFilter} />
          <Spacer />
          <Button
            variant="text"
            color="primary"
            onClick={handleCreateCollection}
            startIcon={<AddIcon />}
          >
            Create project form kit
          </Button>
        </Row>
        <SitelineText variant="body2" color="grey50" className="helperText">
          When onboarding project forms, customers have the option to pick a &quot;Project
          template&quot; that includes forms of all categories. For example, the full collection of
          forms for a certain GC (including all pay app, lien waiver, and change order forms) may be
          saved as a project form kit so customers can easily adopt those forms on all the GC&apos;s
          projects.
        </SitelineText>
      </Column>
      {creatingCollection && (
        <Grid2 size={{ xs: 12 }}>
          <CompanyDetailsProjectFormCollection
            onCancelCreate={handleCancelCollection}
            company={company}
            collection={null}
            generalContractors={allGeneralContractors}
          />
        </Grid2>
      )}
      {searchedCollections.map((collection) => (
        <Grid2 size={{ xs: 12 }} key={collection.id}>
          <CompanyDetailsProjectFormCollection
            collection={collection}
            company={company}
            generalContractors={allGeneralContractors}
          />
        </Grid2>
      ))}
    </StyledCompanyDetailsProjectFormCollections>
  )
}
