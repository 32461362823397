import { gql } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { IntegrationType } from '../../../common/graphql/apollo-operations'
import {
  GcPayIntegration,
  Sage100ContractorIntegration,
  Sage300CreIntegration,
  TexturaIntegration,
} from '../../../common/util/Integration'
import { ContractForDetails } from '../ContractDetails'
import { AddContractIntegrationModal } from './AddContractIntegrationModal'
import { ContractDetailsIntegrationRow } from './ContractDetailsIntegrationRow'
import { GcPayIntegrationDetails } from './integrations/GcPayIntegrationDetails'
import { Hh2IntegrationDetails } from './integrations/Hh2IntegrationDetails'
import { TexturaIntegrationDetails } from './integrations/TexturaIntegrationDetails'

const useStyles = makeStylesFast((theme) => ({
  field: {
    marginTop: theme.spacing(2),
  },
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`

interface ContractDetailsIntegrationsProps {
  contract: ContractForDetails
}

export default function ContractDetailsIntegrations({
  contract,
}: ContractDetailsIntegrationsProps) {
  const classes = useStyles()
  const [isAddIntegrationModalOpen, setIsAddIntegrationModalOpen] = useState(false)

  // Linter disabled because it doesn't detect that there is only one integration type
  const integrationDetails = contract.integrations.map((integration) => {
    switch (integration.type) {
      case IntegrationType.GC_PAY:
        return (
          <GcPayIntegrationDetails
            key={integration.type}
            integration={integration as GcPayIntegration}
          />
        )
      case IntegrationType.TEXTURA:
        return (
          <TexturaIntegrationDetails
            key={integration.type}
            integration={integration as TexturaIntegration}
            contract={contract}
          />
        )
      case IntegrationType.SAGE_100_CONTRACTOR:
        return (
          <Hh2IntegrationDetails
            key={integration.type}
            integration={integration as Sage100ContractorIntegration}
          />
        )
      case IntegrationType.SAGE_300_CRE:
        return (
          <Hh2IntegrationDetails
            key={integration.type}
            integration={integration as Sage300CreIntegration}
          />
        )
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.COMPUTER_EASE_FILE:
      case IntegrationType.VISTA:
      case IntegrationType.SPECTRUM:
      case IntegrationType.TEST:
      case IntegrationType.PROCORE:
      case IntegrationType.FOUNDATION:
      case IntegrationType.ACUMATICA:
      case IntegrationType.SAGE_INTACCT:
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      case IntegrationType.SAGE_300_CRE_AGAVE:
      case IntegrationType.CMIC:
        return null
    }
  })

  const sortedIntegrations = useMemo(() => {
    const collator = new Intl.Collator()
    return [...contract.integrations].sort((a, b) => collator.compare(a.longName, b.longName))
  }, [contract.integrations])

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title="Integrations"
          subtitle="The following integrations are enabled on this contract"
        />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Integration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedIntegrations.map((integration) => (
                <ContractDetailsIntegrationRow
                  key={integration.id}
                  contract={contract}
                  integration={integration}
                />
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Button startIcon={<AddIcon />} onClick={() => setIsAddIntegrationModalOpen(true)}>
            Add contract integration
          </Button>
        </CardActions>
      </Card>
      <AddContractIntegrationModal
        open={isAddIntegrationModalOpen}
        onClose={() => setIsAddIntegrationModalOpen(false)}
        contract={contract}
      />
      {integrationDetails}
    </>
  )
}
