import { Autocomplete, Chip, Link, styled, TextField } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  BillingType,
  colors,
  Row,
  SitelineText,
  SitelineTooltip,
  useToggle,
} from 'siteline-common-web'

const StyledSelectFormForCollectionText = styled(SitelineText)(() => ({
  '& .link': {
    color: colors.grey90,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: colors.green70,
    },
  },
  '& .processingChip': {
    backgroundColor: colors.yellow20,
    color: colors.yellow50,
    cursor: 'default',
  },
}))

export type TemplateSetProperties = {
  id: string
  name: string
  billingType?: BillingType
}

interface SelectFormSetForCollectionProps {
  billingType?: BillingType
  isEditing: boolean
  companyTemplateSets: TemplateSetProperties[]
  selectedTemplateSet: TemplateSetProperties | null
  onSelectTemplateSet: (templateSet: TemplateSetProperties | null) => void
  isProcessingForms: boolean
}

export function SelectFormSetForCollection({
  billingType,
  isEditing,
  selectedTemplateSet,
  companyTemplateSets,
  onSelectTemplateSet,
  isProcessingForms,
}: SelectFormSetForCollectionProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const [isAutocompleteOpen, handleOpen, handleClose] = useToggle()

  const companyTemplateSetOptions = useMemo(() => {
    const filteredCompanyTemplateSets = billingType
      ? companyTemplateSets.filter(
          (companyTemplateSet) => companyTemplateSet.billingType === billingType
        )
      : companyTemplateSets
    return filteredCompanyTemplateSets.map((companyTemplateSet) => ({
      id: companyTemplateSet.id,
      name: companyTemplateSet.name,
    }))
  }, [billingType, companyTemplateSets])

  const handleChange = useCallback(
    (value: TemplateSetProperties | null) => {
      const templateSetId = value?.id
      const foundTemplateSet = companyTemplateSets.find(({ id }) => id === templateSetId)
      onSelectTemplateSet(foundTemplateSet ?? null)
    },
    [companyTemplateSets, onSelectTemplateSet]
  )

  if (!isEditing && !!selectedTemplateSet) {
    return (
      <StyledSelectFormForCollectionText variant="body1" bold>
        <Row gap={8}>
          <Link component={RouterLink} to={`formSets#${selectedTemplateSet.id}`} className="link">
            {selectedTemplateSet.name}
          </Link>
          {isProcessingForms && (
            <SitelineTooltip title="One or more forms from this set are still being built">
              <Chip label="Processing" className="processingChip" size="small" />
            </SitelineTooltip>
          )}
        </Row>
      </StyledSelectFormForCollectionText>
    )
  }

  if (!isEditing && !selectedTemplateSet) {
    return (
      <SitelineText variant="body1" bold>
        -
      </SitelineText>
    )
  }

  if (companyTemplateSets.length === 0) {
    return (
      <SitelineText variant="body1" bold>
        No form sets found
      </SitelineText>
    )
  }
  return (
    <Autocomplete
      options={companyTemplateSetOptions}
      open={isAutocompleteOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={searchQuery}
      onInputChange={(_, value) => setSearchQuery(value)}
      onChange={(_, value) => handleChange(value)}
      getOptionLabel={(option) => option.name}
      value={selectedTemplateSet}
      renderInput={(params) => <TextField {...params} />}
      style={{ width: 400 }}
      multiple={false}
      size="small"
    />
  )
}
