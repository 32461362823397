import { gql } from '@apollo/client'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { styled } from '@mui/system'
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  ButtonLabelSpinner,
  Column,
  FormTemplateType,
  fuseSearch,
  Row,
  SitelineText,
  Spacer,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { StateDropdown } from '../../common/components/StateDropdown'
import { AutocompletedVariant } from '../../common/components/VariantAutocomplete'
import {
  BillingType,
  CompanyFormTemplateCollectionProperties,
  useCreateCompanyFormTemplateCollectionMutation,
  useDeleteCompanyFormTemplateCollectionMutation,
  useUpdateCompanyFormTemplateCollectionMutation,
} from '../../common/graphql/apollo-operations'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  areLienWaiverFormsProcessing,
  arePayAppFormsProcessing,
} from '../../common/util/FormTemplate'
import { SelectFormForCollection } from './SelectFormForCollection'
import { SelectFormSetForCollection, TemplateSetProperties } from './SelectFormSetForCollection'

const StyledCompanyDetailsProjectFormCollection = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .accordionSummary': {
    height: 64,
  },
  '& .collectionHeader': {
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  '& .editingTopLevel': {
    padding: theme.spacing(2, 5),
  },
  '& .formCollectionInfo': {
    padding: theme.spacing(0, 5, 3),
  },
  '& .formSetTitle': {
    width: 200,
  },
  '& .deleteButton': {
    width: 'fit-content',
    alignSelf: 'flex-end',
  },
}))

gql`
  mutation createCompanyFormTemplateCollection($input: CreateCompanyFormTemplateCollectionInput!) {
    createCompanyFormTemplateCollection(input: $input) {
      id
      formTemplateCollections {
        ...CompanyFormTemplateCollectionProperties
      }
    }
  }
  ${fragments.companyFormTemplateCollection}
`

gql`
  mutation updateCompanyFormTemplateCollection($input: UpdateCompanyFormTemplateCollectionInput!) {
    updateCompanyFormTemplateCollection(input: $input) {
      id
      formTemplateCollections {
        ...CompanyFormTemplateCollectionProperties
      }
    }
  }
  ${fragments.companyFormTemplateCollection}
`

gql`
  mutation deleteCompanyFormTemplateCollection($input: DeleteCompanyFormTemplateCollectionInput!) {
    deleteCompanyFormTemplateCollection(input: $input) {
      id
      formTemplateCollections {
        ...CompanyFormTemplateCollectionProperties
      }
    }
  }
  ${fragments.companyFormTemplateCollection}
`

type GeneralContractorOption = {
  id: string
  name: string
}

const EMPTY_GC_OPTION = {
  id: '',
  name: '',
}

interface CompanyDetailsProjectFormCollectionProps {
  company: DetailedCompany
  collection: CompanyFormTemplateCollectionProperties | null
  onCancelCreate?: () => void
  generalContractors: GeneralContractorOption[]
}

export function CompanyDetailsProjectFormCollection({
  collection,
  onCancelCreate,
  company,
  generalContractors,
}: CompanyDetailsProjectFormCollectionProps) {
  const snackbar = useSitelineSnackbar()

  const [isEditing, handleEdit, handleCancelEdit] = useToggle()
  const [isExpanded, handleExpand, handleCollapse] = useToggle()

  const isNewCollection = collection === null
  const isEditingOrCreating = isEditing || isNewCollection

  const initialCollectionName = collection?.name ?? ''
  const initialBillingType = collection?.billingType ?? BillingType.LUMP_SUM
  const initialGeneralContractor = collection?.generalContractor ?? EMPTY_GC_OPTION
  const initialPayAppFormSet = collection?.payAppForms ?? null
  const initialPrimaryLwSet = collection?.primaryLienWaivers ?? null
  const initialVendorLwSet = collection?.vendorLienWaivers ?? null
  const initialChangeOrderRequest = collection?.changeOrderRequest ?? null
  const initialChangeOrderLog = collection?.changeOrderLog ?? null

  const [collectionName, setCollectionName] = useState<string>(initialCollectionName)
  const [billingType, setBillingType] = useState<BillingType>(initialBillingType)
  const [state, setState] = useState<string | null>(collection?.state ?? null)
  const [gc, setGc] = useState<GeneralContractorOption>(initialGeneralContractor)
  const [gcSearchQuery, setGcSearchQuery] = useState<string>('')
  const [isGcAutocompleteOpen, handleOpenGcAutocomplete, handleCloseGcAutocomplete] = useToggle()
  const [cor, setCor] = useState<AutocompletedVariant | null>(initialChangeOrderRequest)
  const [corLog, setCorLog] = useState<AutocompletedVariant | null>(initialChangeOrderLog)
  const [payAppFormSet, setPayAppFormSet] = useState<TemplateSetProperties | null>(
    initialPayAppFormSet
  )
  const [primaryLwSet, setPrimaryLwSet] = useState<TemplateSetProperties | null>(
    initialPrimaryLwSet
  )
  const [vendorLwSet, setVendorLwSet] = useState<TemplateSetProperties | null>(initialVendorLwSet)

  const [createCollection, { loading: isCreating }] =
    useCreateCompanyFormTemplateCollectionMutation()
  const [updateCollection, { loading: isUpdating }] =
    useUpdateCompanyFormTemplateCollectionMutation()
  const [deleteCollection, { loading: isDeleting }] =
    useDeleteCompanyFormTemplateCollectionMutation()

  const isLoading = isCreating || isUpdating || isDeleting
  const isSaving = isCreating || isUpdating

  const formattedBillingType = useMemo(() => {
    switch (billingType) {
      case BillingType.LUMP_SUM:
        return 'Lump Sum'
      case BillingType.UNIT_PRICE:
        return 'Unit Price'
      case BillingType.TIME_AND_MATERIALS:
        return 'Time and Materials'
      case BillingType.QUICK:
        return 'Quick bill'
    }
  }, [billingType])

  const filteredGeneralContractors = useMemo(() => {
    return fuseSearch(generalContractors, gcSearchQuery, ['name'], { ignoreLocation: true })
  }, [generalContractors, gcSearchQuery])

  const shouldShowPayAppFormsProcessing = useMemo(
    () =>
      collection?.payAppForms
        ? arePayAppFormsProcessing([...collection.payAppForms.payAppRequirementGroups])
        : false,
    [collection?.payAppForms]
  )

  const shouldShowPrimaryLienWaiverFormsProcessing = useMemo(
    () =>
      collection?.primaryLienWaivers?.lienWaivers
        ? areLienWaiverFormsProcessing(collection.primaryLienWaivers.lienWaivers)
        : false,
    [collection?.primaryLienWaivers]
  )

  const shouldShowVendorLienWaiverFormsProcessing = useMemo(
    () =>
      collection?.vendorLienWaivers?.lienWaivers
        ? areLienWaiverFormsProcessing(collection.vendorLienWaivers.lienWaivers)
        : false,
    [collection?.vendorLienWaivers]
  )

  const shouldShowChangeOrderRequestFormProcessing = useMemo(() => {
    if (!collection?.changeOrderRequest) {
      return false
    }
    return collection.changeOrderRequest.template.isCustomerReady === false
  }, [collection?.changeOrderRequest])

  const shouldShowChangeOrderLogFormProcessing = useMemo(() => {
    if (!collection?.changeOrderLog) {
      return false
    }
    return collection.changeOrderLog.template.isCustomerReady === false
  }, [collection?.changeOrderLog])

  const disableSave = useMemo(() => {
    if (isLoading || !collectionName) {
      return true
    }

    const templates = [
      payAppFormSet?.id ?? null,
      primaryLwSet?.id ?? null,
      vendorLwSet?.id ?? null,
      cor?.id ?? null,
      corLog?.id ?? null,
    ]
    return templates.every((templateId) => templateId === null)
  }, [
    collectionName,
    cor?.id,
    corLog?.id,
    isLoading,
    payAppFormSet?.id,
    primaryLwSet?.id,
    vendorLwSet?.id,
  ])

  const handleReset = useCallback(() => {
    setCollectionName(initialCollectionName)
    setBillingType(initialBillingType)
    setState(collection?.state ?? null)
    setGc(initialGeneralContractor)
    setPayAppFormSet(initialPayAppFormSet)
    setPrimaryLwSet(initialPrimaryLwSet)
    setVendorLwSet(initialVendorLwSet)
    setCor(initialChangeOrderRequest)
    setCorLog(initialChangeOrderLog)
    setGcSearchQuery('')
    handleCloseGcAutocomplete()
  }, [
    initialCollectionName,
    collection?.state,
    initialBillingType,
    initialChangeOrderLog,
    initialChangeOrderRequest,
    initialGeneralContractor,
    initialPayAppFormSet,
    initialPrimaryLwSet,
    initialVendorLwSet,
    handleCloseGcAutocomplete,
  ])

  const handleEditClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      handleEdit()
      handleExpand()
    },
    [handleEdit, handleExpand]
  )

  const handleCancelClick = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      if (isNewCollection && onCancelCreate) {
        onCancelCreate()
        return
      }
      handleReset()
      handleCancelEdit()
      handleCollapse()
    },
    [handleCancelEdit, handleCollapse, handleReset, isNewCollection, onCancelCreate]
  )

  const handleSaveClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      try {
        const input = {
          name: collectionName,
          billingType,
          generalContractorCompanyId: gc.id || null,
          state,
          payAppTemplateSetId: payAppFormSet?.id ?? null,
          primaryLienWaiverTemplateSetId: primaryLwSet?.id ?? null,
          vendorLienWaiverTemplateSetId: vendorLwSet?.id ?? null,
          changeOrderRequestVariantId: cor?.id ?? null,
          changeOrderLogVariantId: corLog?.id ?? null,
        }

        if (isNewCollection) {
          await createCollection({ variables: { input: { ...input, companyId: company.id } } })
        } else {
          await updateCollection({
            variables: { input: { ...input, collectionId: collection.id } },
          })
        }
      } catch (error) {
        snackbar.showError(error.message)
        return
      }

      handleCancelEdit()
      if (onCancelCreate) {
        onCancelCreate()
      }
    },
    [
      billingType,
      collection?.id,
      collectionName,
      company.id,
      cor?.id,
      corLog?.id,
      createCollection,
      gc.id,
      handleCancelEdit,
      isNewCollection,
      payAppFormSet?.id,
      primaryLwSet?.id,
      snackbar,
      state,
      updateCollection,
      vendorLwSet?.id,
      onCancelCreate,
    ]
  )

  const handleDeleteClick = useCallback(async () => {
    if (collection === null) {
      handleCancelClick()
      return
    }
    const confirmed = window.confirm(`Are you sure you want to delete ${collection.name}?`)
    if (!confirmed) {
      return
    }

    try {
      await deleteCollection({
        variables: {
          input: {
            collectionId: collection.id,
          },
        },
      })
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [collection, deleteCollection, handleCancelClick, snackbar])

  const handleToggleExpanded = useCallback(
    (_event: SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        handleExpand()
      } else {
        handleCollapse()
      }
    },
    [handleCollapse, handleExpand]
  )

  useEffect(() => {
    handleReset()
  }, [handleReset])

  return (
    <StyledCompanyDetailsProjectFormCollection>
      <Accordion
        expanded={isExpanded || isEditingOrCreating}
        onChange={isEditingOrCreating ? undefined : handleToggleExpanded}
      >
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}>
          <Row alignItems="center" className="collectionHeader" gap={8}>
            <CardHeader title={isNewCollection ? 'New project form kit' : initialCollectionName} />
            {!isEditingOrCreating && (
              <Row gap={24}>
                <SitelineText variant="body1" color="grey50">
                  {formattedBillingType}
                </SitelineText>
                {initialGeneralContractor.name && (
                  <SitelineText variant="body1" color="grey50">
                    {initialGeneralContractor.name}
                  </SitelineText>
                )}
                <SitelineText variant="body1" color="grey50">
                  {state}
                </SitelineText>
              </Row>
            )}
            <Spacer />
            {isEditingOrCreating && (
              <>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleCancelClick}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  disabled={disableSave}
                  startIcon={isSaving ? <ButtonLabelSpinner /> : undefined}
                >
                  Save
                </Button>
              </>
            )}
            {!isEditingOrCreating && (
              <Button variant="text" color="primary" onClick={handleEditClick}>
                Edit
              </Button>
            )}
          </Row>
        </AccordionSummary>
        <CardContent style={{ padding: 0 }}>
          <AccordionDetails style={{ padding: 0 }}>
            {isEditingOrCreating && (
              <Row gap={8} className="editingTopLevel">
                <TextField
                  label="Project form kit name*"
                  type="text"
                  size="small"
                  value={collectionName}
                  style={{ width: 300 }}
                  onChange={(ev) => setCollectionName(ev.target.value)}
                />
                <FormControl>
                  <InputLabel id="billingTypeSelect">Project type*</InputLabel>
                  <Select
                    size="small"
                    labelId="billingTypeSelect"
                    label="Project type*"
                    value={billingType}
                    style={{ width: 200 }}
                    onChange={(ev) => setBillingType(ev.target.value as BillingType)}
                  >
                    <MenuItem value={BillingType.LUMP_SUM}>Lump Sum</MenuItem>
                    <MenuItem value={BillingType.UNIT_PRICE}>Unit Price</MenuItem>
                    <MenuItem value={BillingType.TIME_AND_MATERIALS}>Time and Materials</MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  options={filteredGeneralContractors}
                  open={isGcAutocompleteOpen}
                  onOpen={handleOpenGcAutocomplete}
                  onClose={handleCloseGcAutocomplete}
                  inputValue={gcSearchQuery}
                  onInputChange={(_, value) => setGcSearchQuery(value)}
                  getOptionLabel={(option) => option.name}
                  value={gc}
                  size="small"
                  style={{ width: 300 }}
                  onChange={(_, value) => setGc(value ?? EMPTY_GC_OPTION)}
                  renderInput={(params) => <TextField {...params} label="General contractor" />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                />
                <StateDropdown state={state} onStateChange={setState} />
              </Row>
            )}
            <Column className="formCollectionInfo" gap={24}>
              <Row>
                <SitelineText variant="body1" color="grey50" className="formSetTitle">
                  Pay app
                </SitelineText>
                <SitelineText variant="body1" bold>
                  <SelectFormSetForCollection
                    billingType={billingType}
                    isEditing={isEditingOrCreating}
                    companyTemplateSets={[...company.payAppFormTemplateSets]}
                    selectedTemplateSet={payAppFormSet}
                    onSelectTemplateSet={setPayAppFormSet}
                    isProcessingForms={shouldShowPayAppFormsProcessing}
                  />
                </SitelineText>
              </Row>
              <Row>
                <SitelineText variant="body1" color="grey50" className="formSetTitle">
                  Primary lien waivers
                </SitelineText>
                <SelectFormSetForCollection
                  isEditing={isEditingOrCreating}
                  companyTemplateSets={[...company.primaryLienWaiverTemplateSets]}
                  selectedTemplateSet={primaryLwSet}
                  onSelectTemplateSet={setPrimaryLwSet}
                  isProcessingForms={shouldShowPrimaryLienWaiverFormsProcessing}
                />
              </Row>
              <Row>
                <SitelineText variant="body1" color="grey50" className="formSetTitle">
                  Vendor lien waivers
                </SitelineText>
                <SelectFormSetForCollection
                  isEditing={isEditingOrCreating}
                  companyTemplateSets={[...company.vendorLienWaiverTemplateSets]}
                  selectedTemplateSet={vendorLwSet}
                  onSelectTemplateSet={setVendorLwSet}
                  isProcessingForms={shouldShowVendorLienWaiverFormsProcessing}
                />
              </Row>
              <Row>
                <SitelineText variant="body1" color="grey50" className="formSetTitle">
                  Change order request
                </SitelineText>
                <SelectFormForCollection
                  isEditing={isEditingOrCreating}
                  formTemplateType={FormTemplateType.CHANGE_ORDER_REQUEST}
                  selectedFormTemplateVariant={cor}
                  onSelectFormTemplateVariant={setCor}
                  isProcessingForms={shouldShowChangeOrderRequestFormProcessing}
                />
              </Row>
              <Row>
                <SitelineText variant="body1" color="grey50" className="formSetTitle">
                  Change order log
                </SitelineText>
                <SitelineText variant="body1" bold>
                  <SelectFormForCollection
                    isEditing={isEditingOrCreating}
                    formTemplateType={FormTemplateType.CHANGE_ORDER_LOG}
                    selectedFormTemplateVariant={corLog}
                    onSelectFormTemplateVariant={setCorLog}
                    isProcessingForms={shouldShowChangeOrderLogFormProcessing}
                  />
                </SitelineText>
              </Row>
              {isEditing && (
                <Button
                  variant="text"
                  color="error"
                  className="deleteButton"
                  onClick={handleDeleteClick}
                  startIcon={isDeleting ? <ButtonLabelSpinner /> : undefined}
                  disabled={isLoading}
                >
                  Delete
                </Button>
              )}
            </Column>
          </AccordionDetails>
        </CardContent>
      </Accordion>
    </StyledCompanyDetailsProjectFormCollection>
  )
}
