import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  styled,
  Typography,
} from '@mui/material'
import { MouseEvent, SyntheticEvent, useCallback, useMemo } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { BillingType, colors, Column, Row, SitelineText, useToggle } from 'siteline-common-web'
import { DetailedCompany } from '../../common/graphql/Fragments'

const StyledCompanyDetailsFormSets = styled(Card)(({ theme }) => ({
  '& .accordionSummary': {
    width: '100%',
    alignItems: 'center',
    '& .MuiAccordionSummary-expandIconWrapper': {
      // The expand icon is misaligned with the title due to the second row of helper text.
      // This is a hacky fix to move the icon up.
      marginTop: theme.spacing(-7),
    },
  },
  '& .accordionSummaryTopRow': {
    paddingRight: theme.spacing(1),
  },
  '& .accordionSummaryHelperText': {
    padding: theme.spacing(0, 2, 2),
  },
  '& .formSetsList': {
    marginTop: theme.spacing(-2),
    padding: theme.spacing(0, 2, 2, 2),
    '& .link': {
      color: colors.grey90,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        color: colors.green70,
      },
    },
  },
  '& .noFormSets': {
    padding: theme.spacing(2),
  },
  '& .formSetListSection': {
    padding: theme.spacing(2),
  },
  '& .formSetListItem': {
    padding: theme.spacing(2),
  },
}))

interface CompanyDetailsFormSetsProps {
  company: DetailedCompany
}

export function CompanyDetailsFormSets({ company }: CompanyDetailsFormSetsProps) {
  const [isExpanded, handleExpand, handleCollapse] = useToggle()
  const navigate = useNavigate()

  const lumpSumPayAppFormTemplateSets = useMemo(
    () =>
      company.payAppFormTemplateSets.filter(
        (formTemplateSet) => formTemplateSet.billingType === BillingType.LUMP_SUM
      ),
    [company.payAppFormTemplateSets]
  )

  const unitPricePayAppFormTemplateSets = useMemo(
    () =>
      company.payAppFormTemplateSets.filter(
        (formTemplateSet) => formTemplateSet.billingType === BillingType.UNIT_PRICE
      ),
    [company.payAppFormTemplateSets]
  )

  const timeAndMaterialsPayAppFormTemplateSets = useMemo(
    () =>
      company.payAppFormTemplateSets.filter(
        (formTemplateSet) => formTemplateSet.billingType === BillingType.TIME_AND_MATERIALS
      ),
    [company.payAppFormTemplateSets]
  )

  const handleToggleExpanded = useCallback(
    (_event: SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        handleExpand()
      } else {
        handleCollapse()
      }
    },
    [handleCollapse, handleExpand]
  )

  const handleEditFormSetClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      navigate('formSets')
    },
    [navigate]
  )

  const primaryLienWaiverFormSets = company.primaryLienWaiverTemplateSets
  const vendorLienWaiverFormSets = company.vendorLienWaiverTemplateSets

  const hasLumpSumPayAppFormSets = lumpSumPayAppFormTemplateSets.length > 0
  const hasUnitPricePayAppFormSets = unitPricePayAppFormTemplateSets.length > 0
  const hasTimeAndMaterialsPayAppFormSets = timeAndMaterialsPayAppFormTemplateSets.length > 0
  const hasPrimaryLienWaiverFormSets = primaryLienWaiverFormSets.length > 0
  const hasVendorLienWaiverFormSets = vendorLienWaiverFormSets.length > 0

  const hasFormSets =
    hasLumpSumPayAppFormSets ||
    hasUnitPricePayAppFormSets ||
    hasTimeAndMaterialsPayAppFormSets ||
    hasPrimaryLienWaiverFormSets ||
    hasVendorLienWaiverFormSets

  return (
    <StyledCompanyDetailsFormSets>
      <Accordion expanded={isExpanded} onChange={handleToggleExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionSummary">
          <Column>
            <Row
              alignItems="center"
              justifyContent="space-between"
              gap={8}
              className="accordionSummaryTopRow"
            >
              <CardHeader title="Form Sets" />
              <Button onClick={handleEditFormSetClick}>Edit form sets</Button>
            </Row>
            <SitelineText variant="body2" color="grey50" className="accordionSummaryHelperText">
              Form sets are common groupings of forms by category, e.g. a GC&apos;s standard pay app
              forms or the primary lien waiver forms for a certain state. Customers can select from
              among these sets when onboarding project forms per category, and they can be used in
              admin to build Project form kits.
            </SitelineText>
          </Column>
        </AccordionSummary>
        <CardContent style={{ padding: 0 }}>
          <AccordionDetails className="formSetsList">
            {hasLumpSumPayAppFormSets && (
              <Column className="formSetListSection">
                <Typography variant="h6">Pay app (lump sum)</Typography>
                {lumpSumPayAppFormTemplateSets.map((formTemplateSet) => (
                  <>
                    <SitelineText
                      key={formTemplateSet.id}
                      variant="body2"
                      className="formSetListItem"
                    >
                      <Link
                        component={RouterLink}
                        to={`formSets#${formTemplateSet.id}`}
                        className="link"
                      >
                        {formTemplateSet.name}
                      </Link>
                    </SitelineText>
                    <Divider />
                  </>
                ))}
              </Column>
            )}
            {hasUnitPricePayAppFormSets && (
              <Column className="formSetListSection">
                <Typography variant="h6">Pay app (unit price)</Typography>
                {unitPricePayAppFormTemplateSets.map((formTemplateSet) => (
                  <>
                    <SitelineText
                      key={formTemplateSet.id}
                      variant="body2"
                      className="formSetListItem"
                    >
                      <Link
                        component={RouterLink}
                        to={`formSets#${formTemplateSet.id}`}
                        className="link"
                      >
                        {formTemplateSet.name}
                      </Link>
                    </SitelineText>
                    <Divider />
                  </>
                ))}
              </Column>
            )}
            {hasTimeAndMaterialsPayAppFormSets && (
              <Column className="formSetListSection">
                <Typography variant="h6">Pay app (time and materials)</Typography>
                {timeAndMaterialsPayAppFormTemplateSets.map((formTemplateSet) => (
                  <>
                    <SitelineText
                      key={formTemplateSet.id}
                      variant="body2"
                      className="formSetListItem"
                    >
                      <Link
                        component={RouterLink}
                        to={`formSets#${formTemplateSet.id}`}
                        className="link"
                      >
                        {formTemplateSet.name}
                      </Link>
                    </SitelineText>
                    <Divider />
                  </>
                ))}
              </Column>
            )}
            {hasPrimaryLienWaiverFormSets && (
              <Column className="formSetListSection">
                <Typography variant="h6">Primary lien waiver</Typography>
                {primaryLienWaiverFormSets.map((formTemplateSet) => (
                  <>
                    <SitelineText
                      key={formTemplateSet.id}
                      variant="body2"
                      className="formSetListItem"
                    >
                      <Link
                        component={RouterLink}
                        to={`formSets#${formTemplateSet.id}`}
                        className="link"
                      >
                        {formTemplateSet.name}
                      </Link>
                    </SitelineText>
                    <Divider />
                  </>
                ))}
              </Column>
            )}
            {hasVendorLienWaiverFormSets && (
              <Column className="formSetListSection">
                <Typography variant="h6">Vendor lien waiver</Typography>
                {vendorLienWaiverFormSets.map((formTemplateSet) => (
                  <>
                    <SitelineText
                      key={formTemplateSet.id}
                      variant="body2"
                      className="formSetListItem"
                    >
                      <Link
                        component={RouterLink}
                        to={`formSets#${formTemplateSet.id}`}
                        className="link"
                      >
                        {formTemplateSet.name}
                      </Link>
                    </SitelineText>
                    <Divider />
                  </>
                ))}
              </Column>
            )}
            {!hasFormSets && (
              <SitelineText variant="body1" color="grey50" className="noFormSets">
                No form sets found
              </SitelineText>
            )}
          </AccordionDetails>
        </CardContent>
      </Accordion>
    </StyledCompanyDetailsFormSets>
  )
}
