import { Autocomplete, TextField } from '@mui/material'
import { US_STATES } from '../util/Location'

interface StateDropdownProps {
  state: string | null
  onStateChange: (state: string | null) => void
}

export function StateDropdown({ state, onStateChange }: StateDropdownProps) {
  return (
    <Autocomplete
      options={US_STATES}
      value={state || ''}
      sx={{ width: 150 }}
      size="small"
      onChange={(_event, newValue) => onStateChange(newValue || null)}
      renderInput={(params) => <TextField {...params} label="State" />}
      // The user can type to search, but cannot add new values
      freeSolo={false}
    />
  )
}
