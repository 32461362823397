import { gql } from '@apollo/client'
import DataObjectIcon from '@mui/icons-material/DataObject'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import ReplayIcon from '@mui/icons-material/Replay'
import { IconButton, Link, TableCell, TableRow, Tooltip } from '@mui/material'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  WriteSyncOperationStatus,
  getIntegrationBaseName,
  integrationTypes,
} from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar, useToggle } from 'siteline-common-web'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import {
  PaginatedWriteSyncOperationsQuery,
  useRequeueWriteSyncMutation,
} from '../../common/graphql/apollo-operations'
import { openLogs } from '../jobs/JobsPage'
import { MarkAsFailedDialog } from './MarkAsFailedDialog'
import { WriteSyncOperationPayload } from './WriteSyncOperationPayload'
import { WriteSyncOperationStatusChip } from './WriteSyncOperationStatusChip'

gql`
  mutation requeueWriteSync($input: RequeueWriteSyncInput!) {
    requeueWriteSync(input: $input) {
      id
      updatedAt
      status
      result
    }
  }
`

const useStyles = makeStylesFast(() => ({
  row: {
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}))

export type WriteSyncOperation =
  PaginatedWriteSyncOperationsQuery['paginatedWriteSyncOperations']['operations'][number]

type WriteSyncOperationRowProps = {
  operation: WriteSyncOperation
}

/**
 * A single write sync operation row in the list of operations.
 */
export function WriteSyncOperationRow({ operation: operation }: WriteSyncOperationRowProps) {
  const classes = useStyles()
  const [isPayloadDialogOpen, openPayloadDialog, closePayloadDialog] = useToggle()
  const snackbar = useSitelineSnackbar()
  const [isMarkAsFailedDialogOpen, openMarkAsFailedDialog, closeMarkAsFailedDialog] = useToggle()
  const [requeue] = useRequeueWriteSyncMutation()
  const contract = operation.integration.contract
  const projectNumber = contract.internalProjectNumber ?? contract.project.projectNumber

  const timeZone = 'America/Los_Angeles'
  const createdAt = moment.tz(operation.createdAt, timeZone)
  const updatedAt = moment.tz(operation.updatedAt, timeZone)
  const diff = updatedAt.diff(createdAt)
  const durationObj = moment.duration(diff)
  const duration =
    durationObj.minutes() > 0
      ? `${durationObj.minutes()}m${durationObj.seconds()}s`
      : `${durationObj.seconds()}s`
  const integrationName = getIntegrationBaseName(operation.integration.type)

  const canMarkAsFailed = useMemo(
    () =>
      [
        WriteSyncOperationStatus.NOT_STARTED,
        WriteSyncOperationStatus.RUNNING,
        WriteSyncOperationStatus.QUEUED_IN_HH2,
        WriteSyncOperationStatus.DEFERRED,
      ].includes(operation.status),
    [operation.status]
  )
  const canRequeue = useMemo(
    () =>
      [
        WriteSyncOperationStatus.NOT_STARTED,
        WriteSyncOperationStatus.CANCELED,
        WriteSyncOperationStatus.RUNNING,
        WriteSyncOperationStatus.QUEUED_IN_HH2,
        WriteSyncOperationStatus.DEFERRED,
      ].includes(operation.status),
    [operation.status]
  )

  const type = useMemo(() => {
    const payload = operation.payload as integrationTypes.WriteSyncPayload
    switch (payload.type) {
      case 'payAppTextura':
      case 'payAppGcPay':
      case 'payAppProcore':
      case 'payAppSage100':
      case 'payAppFoundation':
      case 'payAppManual':
      case 'payAppLineItemsSage300':
      case 'payAppLineItemsSpectrum':
      case 'payAppLineItemsVista':
      case 'payAppLineItemsAcumatica':
      case 'payAppLineItemsSageIntacct':
      case 'payAppQuickbooks':
      case 'payAppFoundationFileGenie':
      case 'payAppFoundationFileFsi':
      case 'payAppComputerEase':
      case 'payAppCmic':
        return 'Pay app'
      case 'legalRequirement':
        return 'Compliance'
      case 'lienWaivers':
        return 'Lien waivers'
    }
  }, [operation.payload])

  const handleOpenLogs = useCallback(() => {
    const startTime = moment.utc(operation.createdAt).subtract(1, 'minute')
    const endTime = startTime.clone().add(1, 'day')
    const filter = `
      resource.type = "cloud_run_revision"
      resource.labels.service_name = "integrations"
      resource.labels.location = "us-central1"
      severity>=DEFAULT
      timestamp>="${startTime.toISOString()}"
      timestamp<="${endTime.toISOString()}"
      "${operation.id}"
    `
    openLogs({ filter })
  }, [operation.createdAt, operation.id])

  const handleRequeue = useCallback(() => {
    const confirmed = window.confirm(
      'Are you sure you want to requeue this operation? Only do this if you are sure the operation is stuck.'
    )
    if (!confirmed) {
      return
    }
    snackbar.showLoading('Requeuing operation...')
    requeue({
      variables: {
        input: { writeSyncOperationId: operation.id },
      },
    })
      .then(() => {
        snackbar.showSuccess('Operation has been requeued')
      })
      .catch((err) => {
        snackbar.showError(err)
      })
  }, [operation.id, requeue, snackbar])

  return (
    <TableRow hover key={operation.id} className={classes.row}>
      <TableCell>
        <Tooltip
          placement="top"
          arrow
          title=<span>
            Created: {createdAt.toISOString()}
            <br />
            Updated: {updatedAt.toISOString()}
          </span>
        >
          <span>{createdAt.format('M/D/YY HH:mma')}</span>
        </Tooltip>
      </TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell sx={{ maxWidth: '200px' }}>
        <Link component={RouterLink} to={`/users/${operation.createdBy.id}`} target="_blank">
          {operation.createdBy.email}
        </Link>
      </TableCell>
      <TableCell>
        <WriteSyncOperationStatusChip operation={operation} />
      </TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{integrationName}</TableCell>
      <TableCell sx={{ maxWidth: '240px' }}>
        <Link component={RouterLink} to={`/contracts/${contract.id}`} target="_blank">
          {projectNumber} • {contract.project.name}
        </Link>
      </TableCell>
      <TableCell>
        <Tooltip title="See logs">
          <IconButton onClick={handleOpenLogs}>
            <FormatAlignLeftIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="See payload/result">
          <IconButton onClick={openPayloadDialog}>
            <DataObjectIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {canMarkAsFailed && (
          <Tooltip title="Mark as failed">
            <IconButton onClick={openMarkAsFailedDialog}>
              <ErrorOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {canRequeue && (
          <Tooltip title="Re-queue operation">
            <IconButton onClick={handleRequeue}>
              <ReplayIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <IdentifierIconButton id={operation.id} />
        <WriteSyncOperationPayload
          operation={operation}
          open={isPayloadDialogOpen}
          onClose={closePayloadDialog}
        />
        <MarkAsFailedDialog
          operation={operation}
          open={isMarkAsFailedDialogOpen}
          onClose={closeMarkAsFailedDialog}
        />
      </TableCell>
    </TableRow>
  )
}
