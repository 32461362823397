import { NetworkStatus } from '@apollo/client'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import { encode } from 'js-base64'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import {
  CloudLoggingLogsQueryVariables,
  useCloudLoggingLogsQuery,
} from '../../common/graphql/apollo-operations'
import { JobRow } from './JobRow'
import { Job, LogEntry, openLogs } from './JobsPage'

const useStyles = makeStylesFast(() => ({
  root: {
    height: 444,
    overflow: 'auto',
    width: '100%',
    '& .MuiTableFooter-root': {
      position: 'sticky',
      insetBlockEnd: 0,
      background: 'white',
    },
  },
}))

type JobListProps = {
  job: Job | null
  onJobSelected: (job: Job) => void
}

/**
 * List of recent cron job executions.
 */
export default function JobList({ job, onJobSelected }: JobListProps) {
  const classes = useStyles()

  // Gathers recent execution attempts in Cloud Scheduler
  const oneMonthAgo = useMemo(() => moment.utc().subtract(1, 'month'), [])
  const filter = useMemo(() => {
    return `
      resource.type="cloud_scheduler_job"
      resource.labels.location="us-central1"
      resource.labels.job_id!="monitorHh2Exports"
      jsonPayload.@type="type.googleapis.com/google.cloud.scheduler.logging.AttemptStarted"
      timestamp>="${oneMonthAgo.toISOString()}"
    `
  }, [oneMonthAgo])

  const input: CloudLoggingLogsQueryVariables['input'] = useMemo(
    () => ({
      filter,
      pageSize: 20,
    }),
    [filter]
  )
  const { data, loading, fetchMore, networkStatus } = useCloudLoggingLogsQuery({
    variables: { input },
    notifyOnNetworkStatusChange: true,
  })

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        input: { ...input, pageToken: data?.cloudLoggingLogs.nextPageToken },
      },
    })
  }, [data?.cloudLoggingLogs.nextPageToken, fetchMore, input])

  const hasMore = useMemo(
    () => Boolean(data?.cloudLoggingLogs.nextPageToken),
    [data?.cloudLoggingLogs.nextPageToken]
  )

  const isLoadingMore = useMemo(
    () => networkStatus === NetworkStatus.fetchMore && data,
    [data, networkStatus]
  )

  const jobs = useMemo(() => {
    const entries = (data?.cloudLoggingLogs.entries ?? []) as LogEntry[]
    return entries.map((entry): Job => {
      const name = entry.resource.labels.job_id ?? 'unknown'
      const scheduleTime = entry.message.scheduledTime
      const id = encode(JSON.stringify({ name, scheduleTime }))
      return {
        id,
        name,
        startLog: entry,
        endLog: null,
      }
    })
  }, [data])

  const handleOpenLogs = useCallback(() => openLogs({ filter }), [filter])

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 100 }}>Time</TableCell>
            <TableCell>Job</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data && loading && (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
            </TableRow>
          )}
          {jobs.map((jobInList) => (
            <JobRow
              key={jobInList.startLog['logging.googleapis.com/insertId']}
              job={jobInList}
              selected={jobInList.id === job?.id}
              onClick={() => onJobSelected(jobInList)}
            />
          ))}
          {isLoadingMore && (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          {!isLoadingMore && hasMore && (
            <TableRow>
              <TableCell colSpan={2}>
                <Button onClick={loadMore} size="small">
                  Load more...
                </Button>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={2}>
              <Button
                variant="text"
                color="primary"
                size="small"
                startIcon={<FormatAlignLeftIcon />}
                onClick={handleOpenLogs}
              >
                Open logs
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
