import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  styled,
  Typography,
} from '@mui/material'
import { MouseEvent, PropsWithChildren, SyntheticEvent, useCallback } from 'react'
import { ButtonLabelSpinner, Row, SitelineText, Spacer, useToggle } from 'siteline-common-web'

const StyledFormSetCard = styled(Card)(({ theme }) => ({
  '& .accordionSummary': {
    height: 64,
  },
  '& .header': {
    width: '100%',
    padding: theme.spacing(1),
  },
}))

interface FormSetsCardProps {
  initialIsOpen: boolean
  name: string
  isNewFormSet: boolean
  onCancelCreate?: () => void
  isEditing: boolean
  onEdit: () => void
  onCancelEdit: () => void
  onSave: () => Promise<void>
  saving: boolean
  disableSave: boolean
  disableCancel: boolean
  gc: string
  state: string
}

export function FormSetsCard({
  initialIsOpen,
  isNewFormSet,
  name,
  children,
  onCancelCreate,
  isEditing,
  onEdit,
  onCancelEdit,
  onSave,
  saving,
  disableSave,
  disableCancel,
  gc,
  state,
}: PropsWithChildren<FormSetsCardProps>) {
  const [isExpanded, handleExpand, handleCollapse] = useToggle(initialIsOpen)

  const isEditingOrCreating = isEditing || isNewFormSet

  const handleToggleExpanded = useCallback(
    (_event: SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        handleExpand()
      } else {
        handleCollapse()
      }
    },
    [handleCollapse, handleExpand]
  )

  const handleEditClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onEdit()
      handleExpand()
    },
    [handleExpand, onEdit]
  )

  const handleCancelClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (isNewFormSet && onCancelCreate) {
        onCancelCreate()
        return
      }
      onCancelEdit()
      handleCollapse()
    },
    [handleCollapse, isNewFormSet, onCancelCreate, onCancelEdit]
  )

  const handleSaveClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onSave()
      onCancelEdit()
    },
    [onCancelEdit, onSave]
  )

  return (
    <StyledFormSetCard>
      <Accordion
        expanded={isExpanded || isEditingOrCreating}
        onChange={isEditingOrCreating ? undefined : handleToggleExpanded}
      >
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}>
          <Row alignItems="center" className="header" gap={8}>
            <Typography variant="h6">{isNewFormSet ? 'New form set' : name}</Typography>
            {!isEditingOrCreating && (
              <Row gap={24}>
                {gc.length > 0 && (
                  <SitelineText variant="body1" color="grey50">
                    {gc}
                  </SitelineText>
                )}
                {state.length > 0 && (
                  <SitelineText variant="body1" color="grey50">
                    {state}
                  </SitelineText>
                )}
              </Row>
            )}
            <Spacer />
            {isEditingOrCreating && (
              <>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleCancelClick}
                  disabled={disableCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  disabled={disableSave}
                  startIcon={saving ? <ButtonLabelSpinner /> : undefined}
                >
                  Save
                </Button>
              </>
            )}
            {!isEditingOrCreating && (
              <Button variant="text" color="primary" onClick={handleEditClick}>
                Edit
              </Button>
            )}
          </Row>
        </AccordionSummary>
        <CardContent style={{ padding: 0 }}>
          <AccordionDetails style={{ padding: 0 }}>{children}</AccordionDetails>
        </CardContent>
      </Accordion>
    </StyledFormSetCard>
  )
}
