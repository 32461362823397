import { gql } from '@apollo/client'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BillingType, StoredMaterialsCarryoverType } from 'siteline-common-all'
import { Column, fuseSearch } from 'siteline-common-web'
import { useGetGeneralContractorsQuery } from '../../common/graphql/apollo-operations'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { CompanyFormSetsSection, FormSetType } from './CompanyFormSetsSection'
import { LienWaiverFormSet } from './LienWaiverFormSet'
import { PayAppFormSet } from './PayAppFormSet'

gql`
  query getGeneralContractors($companyId: ID!) {
    generalContractors(companyId: $companyId) {
      id
      name
    }
  }
`

const SEARCH_CONFIG = [
  { name: 'name', weight: 0.75 },
  { name: 'generalContractor.name', weight: 0.5 },
  { name: 'state', weight: 0.5 },
]

interface CompanyFormSetsProps {
  company: DetailedCompany
  searchQuery: string
}

export function CompanyFormSets({ company, searchQuery }: CompanyFormSetsProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const [creatingFormSet, setCreatingFormSet] = useState<FormSetType | null>(null)
  const scrollTo = useRef<string | null>(location.hash ? location.hash.slice(1) : null)

  const { data: generalContractorsData } = useGetGeneralContractorsQuery({
    variables: { companyId: company.id },
  })

  const allGeneralContractors = useMemo(() => {
    if (generalContractorsData === undefined) {
      return []
    }
    return _.orderBy([...generalContractorsData.generalContractors], (gc) => gc.name, ['asc'])
  }, [generalContractorsData])

  const lumpSumPayAppFormTemplateSets = useMemo(() => {
    const templateSets = _.chain(company.payAppFormTemplateSets)
      .filter((templateSet) => templateSet.billingType === BillingType.LUMP_SUM)
      .sortBy((templateSet) => templateSet.name)
      .value()
    return fuseSearch(templateSets, searchQuery, SEARCH_CONFIG, { ignoreLocation: true })
  }, [company.payAppFormTemplateSets, searchQuery])

  const unitPricePayAppFormTemplateSets = useMemo(() => {
    const templateSets = _.chain(company.payAppFormTemplateSets)
      .filter((templateSet) => templateSet.billingType === BillingType.UNIT_PRICE)
      .sortBy((templateSet) => templateSet.name)
      .value()
    return fuseSearch(templateSets, searchQuery, SEARCH_CONFIG, { ignoreLocation: true })
  }, [company.payAppFormTemplateSets, searchQuery])

  const timeAndMaterialsPayAppFormTemplateSets = useMemo(() => {
    const templateSets = _.chain(company.payAppFormTemplateSets)
      .filter((templateSet) => templateSet.billingType === BillingType.TIME_AND_MATERIALS)
      .sortBy((templateSet) => templateSet.name)
      .value()
    return fuseSearch(templateSets, searchQuery, SEARCH_CONFIG, { ignoreLocation: true })
  }, [company.payAppFormTemplateSets, searchQuery])

  const primaryLienWaiverFormSets = useMemo(() => {
    const templateSets = _.orderBy(
      company.primaryLienWaiverTemplateSets,
      (templateSet) => templateSet.name
    )
    return fuseSearch(templateSets, searchQuery, SEARCH_CONFIG, { ignoreLocation: true })
  }, [company.primaryLienWaiverTemplateSets, searchQuery])

  const vendorLienWaiverFormSets = useMemo(() => {
    const templateSets = _.orderBy(
      company.vendorLienWaiverTemplateSets,
      (templateSet) => templateSet.name
    )
    return fuseSearch(templateSets, searchQuery, SEARCH_CONFIG, { ignoreLocation: true })
  }, [company.vendorLienWaiverTemplateSets, searchQuery])

  const handleCancelCreate = useCallback(() => {
    setCreatingFormSet(null)
  }, [])

  // Scroll to element if hash is present
  useEffect(() => {
    // First snap to top instantly
    window.scrollTo(0, 0)
    if (scrollTo.current) {
      const elementId = scrollTo.current
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        // Remove the hash from the URL without a full page reload
        navigate(location.pathname, { replace: true })
      }
    }
  }, [location.hash, location.pathname, navigate])

  const hasLumpSumPayAppFormSets = lumpSumPayAppFormTemplateSets.length > 0
  const hasUnitPricePayAppFormSets = unitPricePayAppFormTemplateSets.length > 0
  const hasTimeAndMaterialsPayAppFormSets = timeAndMaterialsPayAppFormTemplateSets.length > 0
  const hasPrimaryLienWaiverFormSets = primaryLienWaiverFormSets.length > 0
  const hasVendorLienWaiverFormSets = vendorLienWaiverFormSets.length > 0

  const isSearching = searchQuery.length > 0
  const isCreatingPayAppLumpSum = creatingFormSet === FormSetType.PAY_APP_LUMP_SUM
  const isCreatingPayAppUnitPrice = creatingFormSet === FormSetType.PAY_APP_UNIT_PRICE
  const isCreatingPayAppTimeAndMaterial = creatingFormSet === FormSetType.PAY_APP_TIME_AND_MATERIALS
  const isCreatingPrimaryLienWaiver = creatingFormSet === FormSetType.PRIMARY_LIEN_WAIVER
  const isCreatingVendorLienWaiver = creatingFormSet === FormSetType.VENDOR_LIEN_WAIVER
  const usesManualStoredMaterials =
    company.storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL

  return (
    <Column gap={32}>
      <CompanyFormSetsSection
        name="Lump sum (pay app)"
        isEmptyState={!hasLumpSumPayAppFormSets}
        creatingFormSet={creatingFormSet}
        onCreateFormSet={setCreatingFormSet}
        formSetType={FormSetType.PAY_APP_LUMP_SUM}
        shouldDisplaySection={isCreatingPayAppLumpSum || !isSearching || hasLumpSumPayAppFormSets}
      >
        {isCreatingPayAppLumpSum && (
          <PayAppFormSet
            payAppFormSet={null}
            billingType={BillingType.LUMP_SUM}
            usesManualStoredMaterials={usesManualStoredMaterials}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={null}
            companyId={company.id}
          />
        )}
        {lumpSumPayAppFormTemplateSets.map((formTemplateSet) => (
          <PayAppFormSet
            key={formTemplateSet.id}
            payAppFormSet={formTemplateSet}
            billingType={BillingType.LUMP_SUM}
            usesManualStoredMaterials={usesManualStoredMaterials}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={scrollTo.current}
            companyId={company.id}
          />
        ))}
      </CompanyFormSetsSection>
      <CompanyFormSetsSection
        name="Unit price (pay app)"
        isEmptyState={!hasUnitPricePayAppFormSets}
        creatingFormSet={creatingFormSet}
        onCreateFormSet={setCreatingFormSet}
        formSetType={FormSetType.PAY_APP_UNIT_PRICE}
        shouldDisplaySection={
          isCreatingPayAppUnitPrice || !isSearching || hasUnitPricePayAppFormSets
        }
      >
        {isCreatingPayAppUnitPrice && (
          <PayAppFormSet
            payAppFormSet={null}
            billingType={BillingType.UNIT_PRICE}
            usesManualStoredMaterials={usesManualStoredMaterials}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={null}
            companyId={company.id}
          />
        )}
        {unitPricePayAppFormTemplateSets.map((formTemplateSet) => (
          <PayAppFormSet
            key={formTemplateSet.id}
            payAppFormSet={formTemplateSet}
            billingType={BillingType.UNIT_PRICE}
            usesManualStoredMaterials={usesManualStoredMaterials}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={scrollTo.current}
            companyId={company.id}
          />
        ))}
      </CompanyFormSetsSection>
      <CompanyFormSetsSection
        name="Time and materials (pay app)"
        isEmptyState={!hasTimeAndMaterialsPayAppFormSets}
        creatingFormSet={creatingFormSet}
        onCreateFormSet={setCreatingFormSet}
        formSetType={FormSetType.PAY_APP_TIME_AND_MATERIALS}
        shouldDisplaySection={
          isCreatingPayAppTimeAndMaterial || !isSearching || hasTimeAndMaterialsPayAppFormSets
        }
      >
        {isCreatingPayAppTimeAndMaterial && (
          <PayAppFormSet
            payAppFormSet={null}
            billingType={BillingType.TIME_AND_MATERIALS}
            usesManualStoredMaterials={usesManualStoredMaterials}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={null}
            companyId={company.id}
          />
        )}
        {timeAndMaterialsPayAppFormTemplateSets.map((formTemplateSet) => (
          <PayAppFormSet
            key={formTemplateSet.id}
            payAppFormSet={formTemplateSet}
            billingType={BillingType.TIME_AND_MATERIALS}
            usesManualStoredMaterials={usesManualStoredMaterials}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={scrollTo.current}
            companyId={company.id}
          />
        ))}
      </CompanyFormSetsSection>
      <CompanyFormSetsSection
        name="Primary lien waiver"
        isEmptyState={!hasPrimaryLienWaiverFormSets}
        creatingFormSet={creatingFormSet}
        onCreateFormSet={setCreatingFormSet}
        formSetType={FormSetType.PRIMARY_LIEN_WAIVER}
        shouldDisplaySection={
          isCreatingPrimaryLienWaiver || !isSearching || hasPrimaryLienWaiverFormSets
        }
      >
        {isCreatingPrimaryLienWaiver && (
          <LienWaiverFormSet
            lienWaiverFormSet={null}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={null}
            companyId={company.id}
            isVendorSet={false}
          />
        )}
        {primaryLienWaiverFormSets.map((formTemplateSet) => (
          <LienWaiverFormSet
            key={formTemplateSet.id}
            lienWaiverFormSet={formTemplateSet}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={scrollTo.current}
            companyId={company.id}
            isVendorSet={false}
          />
        ))}
      </CompanyFormSetsSection>
      <CompanyFormSetsSection
        name="Vendor lien waiver"
        isEmptyState={!hasVendorLienWaiverFormSets}
        creatingFormSet={creatingFormSet}
        onCreateFormSet={setCreatingFormSet}
        formSetType={FormSetType.VENDOR_LIEN_WAIVER}
        shouldDisplaySection={
          isCreatingVendorLienWaiver || !isSearching || hasVendorLienWaiverFormSets
        }
      >
        {isCreatingVendorLienWaiver && (
          <LienWaiverFormSet
            lienWaiverFormSet={null}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={null}
            companyId={company.id}
            isVendorSet={true}
          />
        )}
        {vendorLienWaiverFormSets.map((formTemplateSet) => (
          <LienWaiverFormSet
            key={formTemplateSet.id}
            lienWaiverFormSet={formTemplateSet}
            onCancelCreate={handleCancelCreate}
            generalContractors={allGeneralContractors}
            scrollToId={scrollTo.current}
            companyId={company.id}
            isVendorSet={true}
          />
        ))}
      </CompanyFormSetsSection>
    </Column>
  )
}
